.popup-Admin-Suppression {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: $black;

  .popup-content {
    background: $white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: $red;
      }
    }

    h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      color:$fontcolor1;
    }

    p {
      margin-bottom: 20px;
      font-size: 1rem;
    }

    .confirm-button,
    .cancel-button {
      padding: 10px 20px;
      margin: 5px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.2s ease;

      &.confirm-button {
        background: red;
        color: $white;

        &:hover {
          background: darken(red, 10%);
        }
      }

      &.cancel-button {
        background: gray;
        color: $white;

        &:hover {
          background: darken(gray, 10%);
        }
      }
    }
  }
}