section.account-user {
  margin-bottom: 60px;

  .info-account-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15%;
    margin: 30px 0;

    .info-personnels-account-user {
      display: flex;
      flex-direction: column;
      gap: 50px;

      .info-field {
        margin-bottom: 15px;

        input {
          width: 100%;
          min-height: 40px;
          margin: 10px 0;
          padding: 10px;
          background: $fontcolor1;
          border: 1px solid $white;
          border-radius: 5px;
          transition: 0.2s ease;

          &::placeholder {
            color: $black;
          }

          &:hover {
            background: $fontcolor1;
          }

          &:focus {
            background: $fontcolor1;
            outline: none;
          }
        }

        label {
          color: $fontcolor1;
          font-weight: bold;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .button-account-user {
    display: flex;
    margin: 20px 0;
    justify-content: center;

    .button-group {
      display: flex;
      gap: 40px;
    }

    button {
      padding: 10px;
      background: $fontcolor1;
      border: 2px solid $white;
      border-radius: 10px;
      color: $black;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        background: $fontcolor1H;
      }
    }
  }

  @include respond-to(small) {
    .info-account-user {
      flex-direction: column;
      gap: 20px;

      .info-personnels-account-user{
        gap: 20px;
        width: 80%
      }

    }

    .button-account-user {
      .button-group {
        flex-direction: row;
        gap: 20px;
      }
    }
  }
}