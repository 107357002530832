.category-entry {
  width: 30%;
  height: 100px;
  border: 2px solid $fontcolor1;
  border-radius: 20px;
  background-color: $bgiconscolor;
  display: flex;
  align-items: center;
  text-align: center;
  transition: 0.2s ease;
  @include respond-to(medium) {
    width: 45%;
  }
  @include respond-to(small) {
    width: 100%;
  }
  &:hover {
    background-color: $bgiconscolorH;
    transform: scale(1.1);
  }
  a {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 1.5rem;
      margin: 0;
      color: $white;
      @include respond-to(medium) {
        font-size: 1.4rem;
      }
      @include respond-to(small) {
        font-size: 1.2rem;
      }
    }
  }
}