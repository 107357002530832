section.album-list{

  .status-user{
    padding: 10px;
    border-radius: 20px;
    margin: 10px 20% 100px 20%;
    border: 2px solid $fontcolor1;
    background-color: $bgiconscolor;

    @include respond-to(small){
      margin: 10px 5% 50px 5%;
    }

    .check-box-condition {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .album-list-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 20%;
    padding: 20px 10px;
    border-radius: 20px;
    border: 2px solid $fontcolor1;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;


    @include respond-to(small) {
      margin: 15px 5%;
      font-size: 0.8rem;
      padding: 10px 5px;
    }
  }
}