section.admin-messages {
  section.new-message {
    margin-bottom: 40px;

    form {
      .new-message-setup {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        h3 {
          text-align: center;
        }

        .info-new-message,
        .city-new-message,
        .links-section {
          flex: 1 1 30%;
          min-width: 300px;
        }

        .info-new-message {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          label {
            display: flex;
            align-items: flex-end;
            width: 80%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto 0;
          }

          p {
            text-align: center;
          }

          input {
            width: 80%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto;
            padding: 5px;
            background: $fontcolor1;
            border: 1px solid $white;
            border-radius: 5px;
            transition: 0.2s ease;

            &::placeholder {
              color: $black2;
            }

            &:hover {
              background: $fontcolor1H;
            }

            &:focus {
              background: $fontcolor1H;
            }
          }
        }

        .links-section {
          margin-left: 20px;

          label {
            display: flex;
            align-items: flex-end;
            width: 90%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto 0;
          }

          input {
            width: 90%;
            min-height: 30px;
            height: 15%;
            margin: 5px auto;
            padding: 5px;
            background: $fontcolor1;
            border: 1px solid $white;
            border-radius: 5px;
            transition: 0.2s ease;

            &::placeholder {
              color: $black2;
            }

            &:hover {
              background: $fontcolor1H;
            }

            &:focus {
              background: $fontcolor1H;
            }
          }
        }
      }

      .description-new-message {
        width: 90%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px auto;

        label {
          margin-bottom: 5px;
          cursor: default;
          background: none;
          border: none;
          color: $white;

          &:hover {
            transform: none;
          }
        }

        textarea {
          flex: 1;
          width: 100%;
          height: 100%;
          resize: none;
          padding: 10px;
          background: $fontcolor1;
          border: 1px solid $white;
          border-radius: 5px;
          transition: 0.2s ease;

          &::placeholder {
            color: $black2;
          }

          &:hover {
            background: $fontcolor1H;
          }

          &:focus {
            background: $fontcolor1H;
          }
        }
      }

      .new-message-content {
        h3 {
          text-align: center;
        }

        .new-message-content-container {
          width: 95%;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: center;

          label {
            display: flex;
            align-items: center;
            padding: 7px;
            height: 50px;
            background: $fontcolor1;
            border: 2px solid $white;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              background: $fontcolor1H;
              transform: scale(1.1);
            }
          }
        }
      }

      .uploaded-logo {
        margin-left: 35px;

        img {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          margin: 5px 0;
        }
      }

      .uploaded-files-container {
        width: 95%;
        margin: 0 auto;
        border: 2px solid $white;
        border-radius: 10px;

        legend {
          margin-left: 20px;
          color: $fontcolor1;
          font-size: 1.3rem;
        }

        .uploaded-files {
          display: flex;
          flex-wrap: wrap;

          i {
            color: $red;
            font-size: 2rem;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              color: $red2;
              transform: scale(1.1);
            }
          }

          .picture-new-message-container {
            display: flex;
            align-items: start;
            margin: 5px 25px 5px 0;

            img {
              border-radius: 10px;
              border: 2px solid $fontcolor1;
              margin: 5px 10px;
            }
          }

          .video-new-message-container {
            display: flex;
            align-items: start;
            margin: 5px 25px 5px 0;

            video {
              border-radius: 10px;
              border: 2px solid $fontcolor1;
              cursor: pointer;
            }
          }
        }
      }

      input[type="submit"]#valid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $fontcolor1;
        color: $black;
        font-size: large;
        border: 2px solid $white;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          background-color: $fontcolor1H;
          transform: scale(1.1);
        }
      }

      input[type="submit"]#invalid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $black;
        color: $white;
        font-size: large;
        border: 2px solid $white;
        border-radius: 10px;
        padding: 5px;
        cursor: not-allowed;
        transition: 0.2s ease;
      }
    }
  }

  section.message-management {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      margin: 30px auto;
      padding: 20px;
      border: 2px solid $white;
      border-radius: 20px;

      .name-select-message {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          margin-left: 30px;
          width: 70%;
          height: 30px;
          padding: 5px;
          background: $fontcolor1;
          border: 1px solid $white;
          border-radius: 5px;
          transition: 0.2s ease;

          &::placeholder {
            color: $black2;
          }

          &:hover {
            background: $fontcolor1H;
          }

          &:focus {
            background: $fontcolor1H;
          }
        }
      }

      .others-select-options {
        margin-top: 20px;
        height: 120px;
        width: 90%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 20px;

        select {
          flex: 1;
          margin: 20px 0;
          padding: 5px;
          font-size: 1rem;
          background-color: $fontcolor1;
          border: 2px solid $white;
          border-radius: 5px;
          transition: 0.2s ease;
          color: black;

          &:hover {
            border: 2px solid $white2;
          }
        }

        .date-publication {
          margin-top: 20px;

          input {
            height: 100%;
            margin: 5px 20px 5px 10px;
            padding: 5px;
            background: $fontcolor1;
            border: 1px solid $white;
            border-radius: 5px;
            transition: 0.2s ease;

            &::placeholder {
              color: $black2;
            }

            &:hover {
              background: $fontcolor1H;
            }

            &:focus {
              background: $fontcolor1H;
            }
          }
        }

      }
    }

    button.btn-refresh {
      @include btn-refresh;
    }

    p {
      margin: 20px 0;
      text-align: center;
      font-size: 1.5rem;
    }

    table.message-table {
      width: 95%;
      margin: 20px auto;
      color: $fontcolor1;

      &, th, td {
        border-collapse: collapse;
        border: 2px solid $white;
        padding: 5px;

        .first-category {
          color: red;
        }

        &.contain-btn {
          text-align: center;

          button:disabled {
            &:hover {
              color: red;
            }
          }
        }
      }

      button {
        width: 100%;
        background-color: $fontcolor1;
        border: 1px solid $white;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: $fontcolor1H;
          color: $white;
        }
      }

      tr.deleted {
        background-color: $red;
      }
    }

    div {
      text-align: center;

      button {
        padding: 5px;
        margin: 5px;
        background-color: $fontcolor1;
        border: 1px solid $white;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: $fontcolor1H;
          color: $white;
        }
      }
    }

    section.message-overview {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .message {
        display: flex;
        align-items: center;
        margin: 15px;
        padding: 10px;
        background-color: $fontcolor1;
        border: $borderWhite;
        border-radius: 10px;

        img {
          width: 200px;
          height: 150px;
          object-fit: cover;
          margin-right: 5px;
        }
      }
    }
  }
}