@keyframes slideDown {
    0% {
      top: -50px;
      opacity: 0;
    }
    100% {
      top: 10%;
      opacity: 1;
    }
  }

  .flash-message {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideDown 0.5s ease-out;

    p {
      color: white;
    }

    @include respond-to(small) {
      top: 15%;
      width: 100%;
      font-size: 0.8rem;
    }
  }

  .flash-message button {
    position: absolute;
    top: 10%;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  }