section.contact {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 90%;
  margin: 0 auto;
  background-color: $bgiconscolor;
  border-radius: 30px;
  padding: 20px;
  margin-top: 20px;
  border: 2px solid $fontcolor1;
  text-align: center;

  .QRCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    img {
      max-width: 300px;
      border: 2px solid $fontcolor1;
      border-radius: 10px;
      padding: 10px;
      background-color: $bgiconscolor;
      @include respond-to(small) {
        max-width: 250px;
      }
    }
  }

  a {
    display: block;
    color: $white;
    font-size: 1.2rem;

    &:hover {
      color: $fontcolor1;
    }

    i {
      font-size: 2rem;
      transform: translate(0, 5px);
    }
  }
}