@font-face {
  font-family: "PoppinsMedium";
  src: url(../assets/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Medium.woff2") format("woff2"), url("../assets/fonts/OpenSans-Medium.woff") format("woff"), url("../assets/fonts/OpenSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Regular";
  src: url("../assets/fonts/OpenSans-Regular.woff2") format("woff2"), url("../assets/fonts/OpenSans-Regular.woff") format("woff"), url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Bold";
  src: url("../assets/fonts/OpenSans-Bold.woff2") format("woff2"), url("../assets/fonts/OpenSans-Bold.woff") format("woff"), url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "PoppinsMedium", sans-serif;
  scrollbar-color: #e2b259 black;
  scrollbar-width: thin;
}

body {
  background: black;
}

.logo-container {
  padding-top: 5vh;
  display: block;
}
.logo-container.pwa-active {
  padding-top: 15vh;
}
@media (max-width: 768px) {
  .logo-container.pwa-active {
    padding-top: 5vh;
  }
}
.logo-container .logo {
  max-width: 500px;
  max-height: 100%;
  padding-top: 20px;
  margin: 0 auto 20px;
}
@media (max-width: 768px) {
  .logo-container .logo {
    padding-top: 70px;
  }
}
.logo-container .logo img {
  width: 100%;
  max-width: 100%;
}
.logo-container .logoLBP {
  padding-top: 0;
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  display: block;
  border-radius: 20px;
  border: 2px solid linen;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  .logo-container .logoLBP {
    margin-top: 80px;
    width: 80%;
  }
}

@keyframes dropdownAppear {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.header-buttons {
  display: flex;
  justify-content: space-between;
}
.header-buttons .back-button {
  position: fixed;
  left: 2.5%;
  top: 2.5%;
  width: 80%;
}
@media (max-width: 768px) {
  .header-buttons .back-button {
    border-right: 2px solid black;
    top: 0;
    left: 0;
  }
}
.header-buttons .back-button button {
  background-color: #e2b259;
  font-size: 1rem;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
}
.header-buttons .back-button button:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .header-buttons .back-button button {
    padding: 10px 0;
    border: none;
    border-radius: 0;
    width: 100%;
  }
}
.header-buttons .account-button {
  position: fixed;
  right: 2.5%;
  top: 2.5%;
  width: 20%;
  display: flex;
  justify-content: end;
}
@media (max-width: 768px) {
  .header-buttons .account-button {
    border-bottom: 1px solid #e2b259;
    top: 0;
    right: 0;
  }
}
.header-buttons .account-button button {
  background-color: #e2b259;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
  font-size: 2rem;
}
.header-buttons .account-button button:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .header-buttons .account-button button:hover {
    background-color: #e2b259;
    transform: scale(1);
  }
}
.header-buttons .account-button button.drop-show {
  border-radius: 20px 20px 0 0;
  background-color: #b1852d;
}
@media (max-width: 768px) {
  .header-buttons .account-button button.drop-show {
    border-radius: 0;
  }
}
.header-buttons .account-button button.drop-show:hover {
  transform: scale(1);
}
@media (max-width: 768px) {
  .header-buttons .account-button button {
    padding: 10px 0;
    border: none;
    border-radius: 0;
    width: 100%;
    font-size: 1.5rem;
  }
}
.header-buttons .dropdown-content {
  position: fixed;
  width: 25%;
  top: 10%;
  right: 2.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid white;
  border-radius: 20px;
  background-color: #b1852d;
  padding: 10px 0;
  gap: 10px;
  font-size: 1.2rem;
  animation: dropdownAppear 0.3s ease-out;
}
@media (max-width: 768px) {
  .header-buttons .dropdown-content {
    top: 5%;
    right: 0;
    width: 100%;
    border-radius: 0;
    border: none;
    border-top: 2px solid black;
  }
}
.header-buttons .dropdown-content.drop-show {
  border-top-right-radius: 0;
}
.header-buttons .dropdown-content .dropdown-item {
  background-color: #e2b259;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: center;
  display: block;
  width: 100%;
  color: black;
  text-decoration: none;
}
.header-buttons .dropdown-content .dropdown-item:hover {
  background-color: #b1852d;
  transform: scale(1.2);
  color: red;
}
@media (max-width: 768px) {
  .header-buttons .dropdown-content .dropdown-item {
    padding: 10px 0;
    border: none;
    border-radius: 0;
    width: 100%;
    font-size: 1rem;
  }
}
@media (max-width: 768px) {
  .header-buttons .dropdown-content {
    top: 5%;
    width: 100%;
  }
}
.header-buttons .dropdown-content a {
  color: black;
}
.header-buttons .dropdown-content .dropdown-item {
  font-size: 1.2rem;
  background-color: #b1852d;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 80%;
  text-align: center;
}

.partner-entry a {
  display: flex;
  margin: 20px 0;
  padding: 30px;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  .partner-entry a {
    padding: 10px;
    margin: 10px 10% 0 10%;
    flex-direction: column;
  }
}
.partner-entry a img {
  padding: 5px;
  min-width: 200px;
  max-width: 200px;
  height: 75px;
  object-fit: contain;
  background-color: linen;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .partner-entry a img {
    min-width: 150px;
    max-width: 150px;
    height: auto;
  }
}
.partner-entry a .text-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
@media (max-width: 768px) {
  .partner-entry a .text-container {
    flex-direction: column;
    align-items: center;
  }
}
.partner-entry a .text-container h2 {
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .partner-entry a .text-container h2 {
    font-size: 1.3rem;
  }
}
.partner-entry a .text-container p {
  align-self: center;
  font-style: italic;
}
@media (max-width: 768px) {
  .partner-entry a .text-container p span {
    display: none;
  }
}

.city-list-entry-container {
  margin-bottom: 40px;
  margin-top: 20px;
}
.city-list-entry-container a {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
  background-color: black;
  border: 2px solid linen;
  border-radius: 20px;
  transition: 0.3s ease;
  position: relative;
}
@media (max-width: 768px) {
  .city-list-entry-container a {
    width: 80%;
    height: fit-content;
  }
}
.city-list-entry-container a img {
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 90%;
  max-height: 250px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}
.city-list-entry-container a .nameDistance {
  color: black;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  font-size: 1.5rem;
  background-color: #e2b259;
  border: hidden;
  align-items: center;
  border-radius: 0 0 17px 17px;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
}
.city-list-entry-container a .name {
  margin-right: 20%;
}
@media (max-width: 768px) {
  .city-list-entry-container a .name {
    margin-right: 0;
  }
}
.city-list-entry-container a .distance {
  position: absolute;
  bottom: 1px;
  right: 15px;
  font-size: 1rem;
  color: black;
}
@media (max-width: 768px) {
  .city-list-entry-container a .distance {
    bottom: 5px;
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .city-list-entry-container a .distance {
    position: static;
  }
}
.city-list-entry-container a:hover {
  transform: scale(1.05);
}
.city-list-entry-container a:hover button {
  background-color: #b1852d;
}

.nav-admin-container {
  margin: 0 auto;
  padding: 0 20px;
  height: 60px;
  width: 100%;
  top: 0;
  background-color: black;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  border-bottom: 2px solid white;
  z-index: 1000;
}
.nav-admin-container ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.nav-admin-container ul li {
  height: 100%;
  width: 100%;
}
.nav-admin-container ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: linen;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: 0.2s ease;
}
.nav-admin-container ul li a:hover {
  background-color: #b1852d;
  color: linen;
}
.nav-admin-container ul li .nav-active {
  background-color: #e2b259;
  color: black;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.category-entry {
  width: 30%;
  height: 100px;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  text-align: center;
  transition: 0.2s ease;
}
@media (max-width: 1024px) {
  .category-entry {
    width: 45%;
  }
}
@media (max-width: 768px) {
  .category-entry {
    width: 100%;
  }
}
.category-entry:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.category-entry a {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-entry a h2 {
  font-size: 1.5rem;
  margin: 0;
  color: linen;
}
@media (max-width: 1024px) {
  .category-entry a h2 {
    font-size: 1.4rem;
  }
}
@media (max-width: 768px) {
  .category-entry a h2 {
    font-size: 1.2rem;
  }
}

.install-pwa {
  position: fixed;
}

.install-pwa .install-btn,
.install-pwa .install-text, .geo-location-instructions .install-btn,
.geo-location-instructions .install-text {
  display: block;
  margin: 0 auto 30px;
  padding: 15px;
  width: 70%;
  font-size: 1.2rem;
  color: black;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 20px;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 768px) {
  .install-pwa .install-btn,
  .install-pwa .install-text, .geo-location-instructions .install-btn,
  .geo-location-instructions .install-text {
    font-size: 0.5rem;
    padding: 5px;
    border: none;
    border-radius: 0;
  }
}
@media (max-width: 768px) {
  .install-pwa .install-btn, .geo-location-instructions .install-btn {
    position: fixed;
    width: 80%;
    border-right: 2px solid black;
    top: 0;
    left: 0;
  }
}
.install-pwa .install-btn-geo-info, .geo-location-instructions .install-btn-geo-info {
  background-color: transparent;
  border: none;
  display: contents;
}
.install-pwa .install-btn-geo-info .geo-info, .geo-location-instructions .install-btn-geo-info .geo-info {
  color: white;
  text-align: center;
  margin: 20px auto;
  padding: 10px;
  background-color: red;
  border-radius: 10px;
  border: 2px solid linen;
  max-width: 600px;
}
@media (max-width: 768px) {
  .install-pwa .install-btn-geo-info .geo-info, .geo-location-instructions .install-btn-geo-info .geo-info {
    padding: 5px;
    width: 80%;
  }
}
.install-pwa .install-btn, .geo-location-instructions .install-btn {
  cursor: pointer;
}
.install-pwa .install-text, .geo-location-instructions .install-text {
  font-size: 0.6rem;
}
@media (max-width: 768px) {
  .install-pwa .install-text, .geo-location-instructions .install-text {
    font-size: 0.45rem;
  }
}
.install-pwa .chrome-btn, .geo-location-instructions .chrome-btn {
  margin: 15px 0;
  position: relative;
  background-color: linen;
  font-size: 1.2rem;
  padding: 10px;
  border: 2px solid black;
  border-radius: 20px;
  cursor: pointer;
  width: 50%;
}
.install-pwa .chrome-btn:hover, .geo-location-instructions .chrome-btn:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .install-pwa .chrome-btn, .geo-location-instructions .chrome-btn {
    font-size: 1rem;
    width: 60%;
  }
}
.install-pwa .popup-content, .geo-location-instructions .popup-content {
  position: relative;
  background-color: black;
  border: 2px solid rgb(175, 33, 33);
  border-radius: 10px;
  padding: 30px;
  width: 60vw;
  height: 60vh;
  max-width: 700px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
  .install-pwa .popup-content, .geo-location-instructions .popup-content {
    width: 90vw;
    height: 70vh;
    max-width: 500px;
    padding: 20px;
  }
}
.install-pwa .popup-content .apple_choose, .geo-location-instructions .popup-content .apple_choose {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  gap: 20px;
}
.install-pwa .popup-welcome, .geo-location-instructions .popup-welcome {
  color: #e2b259;
  height: auto;
}
@media (max-width: 768px) {
  .install-pwa .popup-welcome, .geo-location-instructions .popup-welcome {
    height: auto;
  }
}
.install-pwa .popup-welcome h1, .geo-location-instructions .popup-welcome h1 {
  margin-bottom: 10px;
}
.install-pwa .popup-instruction, .geo-location-instructions .popup-instruction {
  background-color: linen;
  justify-content: flex-start;
  max-height: 1000px;
  height: 80vh;
  overflow-y: auto;
  padding: 30px;
}
.install-pwa .popup-instruction p, .geo-location-instructions .popup-instruction p {
  margin: 15px 0 25px 0;
  font-size: 1rem;
}
.install-pwa .popup-instruction #majuscule, .geo-location-instructions .popup-instruction #majuscule {
  margin-bottom: 5px;
}
.install-pwa .popup-instruction #minuscume, .geo-location-instructions .popup-instruction #minuscume {
  margin-top: 5px;
  text-transform: none;
}
.install-pwa .popup-instruction img, .geo-location-instructions .popup-instruction img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 25px;
}
.install-pwa .popup-instruction .close-button-instruction, .geo-location-instructions .popup-instruction .close-button-instruction {
  top: 10px;
  bottom: auto;
  right: 10px;
  font-size: 1.2rem;
}
.install-pwa .popup-instruction .img-chrome-safari p, .geo-location-instructions .popup-instruction .img-chrome-safari p {
  margin: 5px 0;
}
.install-pwa .popup-iframe, .geo-location-instructions .popup-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.install-pwa .close-button, .geo-location-instructions .close-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
.install-pwa .close-button:hover, .geo-location-instructions .close-button:hover {
  color: rgb(255, 0, 0);
}

.image-gallery {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 50px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
  width: 80%;
  height: 90%;
}
@media (max-width: 1024px) {
  .image-gallery {
    width: 95%;
    height: 70%;
    padding: 10px;
  }
}
.image-gallery .bottom-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.image-gallery .bottom-controls button {
  background: rgba(255, 0, 0, 0.8);
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s;
  margin: 0 10px;
}
.image-gallery .bottom-controls button:hover {
  background: rgba(255, 0, 0, 0.8);
}

.image-gallery img {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
  margin: 10px 0;
}

.image-gallery .controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
}

.image-gallery button {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s;
}

.image-gallery button:hover {
  background: rgba(255, 255, 255, 0.5);
}

.image-gallery .close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 0, 0, 0.8);
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.image-gallery .close-button:hover {
  background: rgb(255, 0, 0);
}

.image-gallery .prev-button,
.image-gallery .next-button {
  opacity: 0;
  pointer-events: auto;
  display: block;
  position: absolute;
  height: 40%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.image-gallery .prev-button {
  left: 10px;
}

.image-gallery .next-button {
  right: 10px;
}

.image-gallery .prev-button:hover,
.image-gallery .next-button:hover {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

@media (max-width: 1024px) {
  .image-gallery .prev-button:hover,
  .image-gallery .next-button:hover {
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
  }
}
.news-list-container {
  width: 25%;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow-y: auto;
}
.news-list-container.link {
  max-height: 50vh;
}
.news-list-container.manual {
  max-height: 80vh;
}

.news-item {
  background: #e2b259;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.news-item img {
  border-radius: 4px;
  margin-bottom: 10px;
}

.news-item h4 {
  margin: 0;
  font-size: 16px;
  color: black;
}

.news-item p {
  margin-top: 10px;
  color: white;
}

table.admin-table {
  width: 95%;
  margin: 20px auto;
  color: #e2b259;
}
table.admin-table, table.admin-table th, table.admin-table td {
  border-collapse: collapse;
  border: 2px solid linen;
  padding: 5px;
}
table.admin-table .first-category, table.admin-table th .first-category, table.admin-table td .first-category {
  color: red;
}
table.admin-table.contain-btn, table.admin-table th.contain-btn, table.admin-table td.contain-btn {
  text-align: center;
}
table.admin-table.contain-btn button:disabled:hover, table.admin-table th.contain-btn button:disabled:hover, table.admin-table td.contain-btn button:disabled:hover {
  color: red;
}
table.admin-table img, table.admin-table th img, table.admin-table td img {
  max-height: 70px;
  max-width: 150px;
}
table.admin-table .city-logo, table.admin-table th .city-logo, table.admin-table td .city-logo {
  filter: contrast(0%);
}
table.admin-table button {
  width: 100%;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
table.admin-table button:hover {
  background-color: #b1852d;
  color: linen;
}
table.admin-table tr.deleted {
  background-color: rgb(175, 33, 33);
}
@media (max-width: 768px) {
  table.admin-table {
    width: 100%;
    margin: 10px 0;
  }
  table.admin-table thead {
    display: none;
  }
  table.admin-table tr {
    display: block;
    margin-bottom: 25px;
    border: 2px solid linen;
  }
  table.admin-table td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-bottom: 2px solid #e2b259;
    padding: 8px 10px;
  }
  table.admin-table td:last-child {
    border: none;
  }
  table.admin-table td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
  }
  table.admin-table button {
    width: 40%;
    padding: 8px;
    font-size: 14px;
  }
}

@keyframes slideDown {
  0% {
    top: -50px;
    opacity: 0;
  }
  100% {
    top: 10%;
    opacity: 1;
  }
}
.flash-message {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.5s ease-out;
}
.flash-message p {
  color: white;
}
@media (max-width: 768px) {
  .flash-message {
    top: 15%;
    width: 100%;
    font-size: 0.8rem;
  }
}

.flash-message button {
  position: absolute;
  top: 10%;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

section.paginated-list section.paginated-list-entry a {
  color: #e2b259;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.75);
}
section.paginated-list section.paginated-list-entry a:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.03);
}
section.paginated-list .load-more-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
}
section.paginated-list .load-more-button:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.05);
}
@media (max-width: 768px) {
  section.paginated-list .load-more-button {
    width: 150px;
    font-size: 0.875rem;
  }
}

.status-user {
  margin-bottom: 20px;
}
.status-user .check-box-condition {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.status-user .check-box-condition p {
  color: #989898;
  font-size: 0.8rem;
  padding: 0 10%;
  text-align: justify;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .status-user .check-box-condition p {
    padding: 0 5%;
    font-size: 0.7rem;
  }
}
.status-user .check-box-condition .checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #e2b259;
  cursor: pointer;
}
.status-user .check-box-condition .checkbox-label input[type=checkbox] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;
}
.status-user .check-box-condition .checkbox-label:hover {
  color: #b1852d;
}

.popup-info-partner .popup-info-partner-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 30px;
  width: 30%;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header {
  display: flex;
  justify-content: right;
  align-items: start;
  margin-bottom: 20px;
  margin-left: 32px;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header img {
  max-width: 80%;
  max-height: 200px;
  border-radius: 20px;
  margin: 0 auto;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-header i:hover {
  color: rgb(255, 0, 0);
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content {
  text-align: center;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content h3 {
  font-size: 2rem;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content .social-media-partner a i {
  cursor: pointer;
  color: black;
  font-size: 3rem;
  transition: 0.2s ease;
}
.popup-info-partner .popup-info-partner-container .popup-info-partner-content .social-media-partner a i:hover {
  transform: scale(1.1);
}

.popup-content-partner .popup-content-partner-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 30px;
  width: 40%;
  text-align: center;
  max-height: 95vh;
  overflow-y: scroll;
}
@media (max-width: 1340px) {
  .popup-content-partner .popup-content-partner-container {
    width: 50%;
  }
}
@media (max-width: 1024px) {
  .popup-content-partner .popup-content-partner-container {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .popup-content-partner .popup-content-partner-container {
    width: 80%;
  }
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header {
  display: flex;
  justify-content: right;
  align-items: start;
  margin-bottom: 20px;
  margin-left: 32px;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header img {
  max-width: 80%;
  max-height: 200px;
  border-radius: 20px;
  margin: 0 auto;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-header i:hover {
  color: rgb(255, 0, 0);
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-text h3 {
  font-size: 2rem;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-text p {
  font-size: 1.3rem;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads {
  margin: 20px 0;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads .popup-content-uploads-container {
  padding: 5px;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads .popup-content-uploads-container img {
  max-width: 100%;
  max-height: 150px;
  border-radius: 10px;
  margin-right: 20px;
}
.popup-content-partner .popup-content-partner-container .popup-content-partner-uploads .popup-content-uploads-container video {
  max-width: 100%;
  max-height: 150px;
  border-radius: 10px;
  margin-right: 20px;
}

form.form-admin-update-partner .update-partner-setup .info-update-partner {
  text-align: center;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .info-update-partner-container, form.form-admin-update-partner .update-partner-setup .info-update-partner .link-update-partner, form.form-admin-update-partner .update-partner-setup .info-update-partner .adress-update, form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update, form.form-admin-update-partner .update-partner-setup .info-update-partner .coordinates-update {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 70px;
  margin-bottom: 20px;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .info-update-partner-container .city-select-container, form.form-admin-update-partner .update-partner-setup .info-update-partner .link-update-partner .city-select-container, form.form-admin-update-partner .update-partner-setup .info-update-partner .adress-update .city-select-container, form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update .city-select-container, form.form-admin-update-partner .update-partner-setup .info-update-partner .coordinates-update .city-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  margin-bottom: 20px;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .info-update-partner-container input, form.form-admin-update-partner .update-partner-setup .info-update-partner .link-update-partner input, form.form-admin-update-partner .update-partner-setup .info-update-partner .adress-update input, form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update input, form.form-admin-update-partner .update-partner-setup .info-update-partner .coordinates-update input {
  width: 23%;
  height: 30px;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update input {
  width: 50%;
  height: 30px;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update #subDomain {
  width: 20%;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update button {
  position: absolute;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
  top: 20px;
  right: 3%;
}
form.form-admin-update-partner .update-partner-setup .info-update-partner .url-update button:hover {
  transform: scale(1.1);
}
form.form-admin-update-partner .update-partner-setup .description-update-partner {
  margin: 10px;
}
form.form-admin-update-partner .update-partner-setup .description-update-partner textarea {
  width: 100%;
  height: 150px;
}
form.form-admin-update-partner .update-partner-setup .categories-update-partner {
  text-align: center;
}
form.form-admin-update-partner .update-partner-setup .categories-update-partner h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
form.form-admin-update-partner .update-partner-setup .social-media-update-partner {
  text-align: center;
}
form.form-admin-update-partner .update-partner-setup .social-media-update-partner h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
form.form-admin-update-partner .update-partner-setup .social-media-update-partner .social-media-update-partner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
form.form-admin-update-partner .update-partner-setup .social-media-update-partner .social-media-update-partner-container input {
  width: 95%;
  height: 30px;
}
form.form-admin-update-partner .update-partner-setup .social-media-update-partner .social-media-update-partner-container .social-media-update {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
form.form-admin-update-partner .update-partner-content h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
  text-align: center;
}
form.form-admin-update-partner .update-partner-content .popup-update-content-container {
  width: 95%;
}
form.form-admin-update-partner .uploaded-logo {
  margin-left: 35px;
}
form.form-admin-update-partner .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
form.form-admin-update-partner .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
form.form-admin-update-partner .uploaded-files-container legend {
  margin-left: 20px;
  color: black;
  font-size: 1.3rem;
}
form.form-admin-update-partner .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
form.form-admin-update-partner .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
form.form-admin-update-partner .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
form.form-admin-update-partner .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
form.form-admin-update-partner .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
form.form-admin-update-partner .uploaded-files-container .uploaded-files .video-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
form.form-admin-update-partner .uploaded-files-container .uploaded-files .video-new-partner-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}
form.form-admin-update-partner .logo-interview {
  display: flex;
}
form.form-admin-update-partner .logo-interview .uploaded-logo {
  margin-left: 35px;
}
form.form-admin-update-partner .logo-interview .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}

.interview-update-partner {
  text-align: center;
  margin: 20px 0;
}
.interview-update-partner h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
.interview-update-partner input {
  height: 30px;
}
.interview-update-partner .interview-informaton-update {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.interview-update-partner .interview-informaton-update .interview-update {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.interview-update-partner .interview-text-update {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.interview-update-partner .interview-text-update .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
}
.interview-update-partner .interview-text-update textarea {
  width: 100%;
  height: 70px;
}
.interview-update-partner .interview-text-update button {
  width: 80%;
  padding: 10px;
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.interview-update-partner .interview-text-update button:hover {
  background-color: #e2b259;
}
.interview-update-partner .interview-text-update .modal-content {
  width: 80%;
  max-width: 800px;
}
.interview-update-partner .interview-text-update .modal-content .textarea-container {
  position: relative;
}
.interview-update-partner .interview-text-update .modal-content .textarea-container .modal-close {
  position: absolute;
  width: 30px;
  top: 2px;
  right: 10px;
  font-size: 1rem;
  background-color: #e2b259;
  border: none;
  cursor: pointer;
  color: rgb(175, 33, 33);
}
.interview-update-partner .interview-text-update .modal-content .textarea-container .modal-close:hover {
  color: rgb(255, 0, 0);
  background-color: white;
}

.popup-city-social-media .popup-content-city-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 30px;
  width: 30%;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header {
  display: flex;
  justify-content: right;
  align-items: start;
  margin-bottom: 20px;
  margin-left: 32px;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header img {
  max-width: 80%;
  max-height: 200px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 5px;
  filter: contrast(0%);
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-header i:hover {
  color: rgb(255, 0, 0);
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media {
  text-align: center;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media h3 {
  font-size: 2rem;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media a i {
  cursor: pointer;
  color: black;
  font-size: 3rem;
  transition: 0.2s ease;
}
.popup-city-social-media .popup-content-city-container .popup-content-city-social-media a i:hover {
  transform: scale(1.1);
}

form .update-city-setup .info-update-city {
  text-align: center;
}
form .update-city-setup .info-update-city h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
form .update-city-setup .info-update-city .info-update-city-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 70px;
}
form .update-city-setup .info-update-city .info-update-city-container input {
  width: 23%;
  height: 30px;
}
form .update-city-setup .info-update-city .social-media-update-city {
  text-align: center;
}
form .update-city-setup .info-update-city .social-media-update-city h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
}
form .update-city-setup .info-update-city .social-media-update-city .social-media-update-city-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
form .update-city-setup .info-update-city .social-media-update-city .social-media-update-city-container input {
  width: 95%;
  height: 30px;
}
form .update-city-setup .info-update-city .social-media-update-city .social-media-update-city-container .social-media-update {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
form .uploads-update-city {
  margin-top: 30px;
}
form .uploads-update-city .uploads-update-city-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
form .uploads-update-city .uploads-update-city-container .button-uploads-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
form .uploads-update-city .uploads-update-city-container .button-uploads-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
form .uploads-update-city .uploads-update-city-container .button-uploads-container label:hover {
  transform: scale(1.1);
}
form .uploads-update-city .uploads-update-city-container .render-uploads-update-city {
  display: flex;
  align-items: center;
}
form .uploads-update-city .uploads-update-city-container .render-uploads-update-city img {
  margin: 20px;
  max-width: 300px;
}
form .uploads-update-city .uploads-update-city-container .render-uploads-update-city .city-logo {
  filter: contrast(0%);
}

form.form-admin-new-guided-tour section.guided-tour-infos .label-info-guided-tour-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
form.form-admin-new-guided-tour section.guided-tour-infos .label-info-guided-tour-container .label-input-guided-tour {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form.form-admin-new-guided-tour section.guided-tour-infos .label-info-guided-tour-container .label-input-guided-tour input {
  height: 30px;
  padding: 3px;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
form.form-admin-new-guided-tour section.guided-tour-infos .label-info-guided-tour-container .label-input-guided-tour input:focus {
  outline: none;
}
form.form-admin-new-guided-tour section.guided-tour-infos .label-info-guided-tour-container .label-btn {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
form.form-admin-new-guided-tour section.guided-tour-infos .label-info-guided-tour-container .label-btn:hover {
  transform: scale(1.1);
}
form.form-admin-new-guided-tour section.guided-tour-infos img {
  display: block;
  max-width: 200px;
  max-height: 300px;
  margin: 20px auto;
  border: 2px solid linen;
  border-radius: 10px;
}
form.form-admin-new-guided-tour section.guided-tour-audio {
  text-align: center;
}
form.form-admin-new-guided-tour section.guided-tour-audio .searchbar-country {
  margin-bottom: 20px;
}
form.form-admin-new-guided-tour section.guided-tour-audio .searchbar-country label {
  font-size: 1.3rem;
  margin-right: 20px;
}
form.form-admin-new-guided-tour section.guided-tour-audio .searchbar-country input {
  width: 23%;
  height: 30px;
  padding: 5px;
  font-size: 1rem;
  background-color: linen;
  border: 2px solid black;
  border-radius: 10px;
}
form.form-admin-new-guided-tour section.guided-tour-audio .searchbar-country input:focus {
  outline: none;
}
form.form-admin-new-guided-tour section.guided-tour-audio .flags-container {
  border: 2px solid linen;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
form.form-admin-new-guided-tour section.guided-tour-audio .flags-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form.form-admin-new-guided-tour section.guided-tour-audio .flags-container div img {
  width: 50px;
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language div {
  text-align: right;
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language div i {
  font-size: 2rem;
  width: fit-content;
  text-align: end;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language div i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country label img {
  width: 100px;
}
form.form-admin-new-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country audio {
  min-width: 250px;
}

form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour {
  text-align: center;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour .info-update-guided-tour-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 70px;
  margin-bottom: 20px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour .info-update-guided-tour-container input {
  width: 23%;
  height: 30px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content {
  display: flex;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content img {
  display: block;
  max-width: 600px;
  max-height: 300px;
  margin: 20px auto;
  border: 2px solid linen;
  border-radius: 10px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content .label-btn {
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour .update-guided-tour-content .label-btn:hover {
  transform: scale(1.1);
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio {
  text-align: center;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .searchbar-country {
  margin-bottom: 20px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .searchbar-country label {
  font-size: 1.3rem;
  margin-right: 20px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .searchbar-country input {
  width: 23%;
  height: 30px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .flags-container {
  border: 2px solid linen;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .flags-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .flags-container div img {
  width: 50px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div {
  text-align: right;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div i {
  font-size: 2rem;
  width: fit-content;
  text-align: end;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country label img {
  width: 100px;
}
form.form-admin-update-guided-tour .update-guided-tour-setup .info-update-guided-tour section.guided-tour-audio .audio-each-language div .center-audio-country audio {
  min-width: 250px;
}

.popup-Admin-Suppression {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: black;
}
.popup-Admin-Suppression .popup-content {
  background: linen;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
}
.popup-Admin-Suppression .popup-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.popup-Admin-Suppression .popup-content .close-button:hover {
  color: rgb(175, 33, 33);
}
.popup-Admin-Suppression .popup-content h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #e2b259;
}
.popup-Admin-Suppression .popup-content p {
  margin-bottom: 20px;
  font-size: 1rem;
}
.popup-Admin-Suppression .popup-content .confirm-button,
.popup-Admin-Suppression .popup-content .cancel-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
}
.popup-Admin-Suppression .popup-content .confirm-button.confirm-button,
.popup-Admin-Suppression .popup-content .cancel-button.confirm-button {
  background: red;
  color: linen;
}
.popup-Admin-Suppression .popup-content .confirm-button.confirm-button:hover,
.popup-Admin-Suppression .popup-content .cancel-button.confirm-button:hover {
  background: #cc0000;
}
.popup-Admin-Suppression .popup-content .confirm-button.cancel-button,
.popup-Admin-Suppression .popup-content .cancel-button.cancel-button {
  background: gray;
  color: linen;
}
.popup-Admin-Suppression .popup-content .confirm-button.cancel-button:hover,
.popup-Admin-Suppression .popup-content .cancel-button.cancel-button:hover {
  background: rgb(102.5, 102.5, 102.5);
}

form.form-admin-update-user .form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
form.form-admin-update-user .form-row .form-group {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

section.popup {
  color: black;
}
section.popup .popup-update-container {
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  max-height: 95vh;
  overflow-y: scroll;
}
section.popup .popup-update-container i {
  font-size: 2rem;
  color: rgb(175, 33, 33);
  cursor: pointer;
  transition: 0.2s ease;
}
section.popup .popup-update-container i:hover {
  color: rgb(255, 0, 0);
}
section.popup .popup-update-container .popup-update-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}
section.popup .popup-update-container .popup-update-header h2 {
  display: contents;
  color: black;
  font-size: 1.8rem;
}
section.popup .popup-update-container .popup-update-header .cancel-button {
  display: flex;
  align-items: center;
  color: rgb(175, 33, 33);
  cursor: pointer;
}
section.popup .popup-update-container .popup-update-header .cancel-button p {
  transition: 0.2s ease;
}
section.popup .popup-update-container .popup-update-header .cancel-button:hover p, section.popup .popup-update-container .popup-update-header .cancel-button:hover i {
  color: rgb(255, 0, 0);
}
section.popup .popup-update-container form label {
  margin: 10px 0 5px;
}
section.popup .popup-update-container form input, section.popup .popup-update-container form textarea {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 10px;
  transition: border-color 0.3s;
  background-color: linen;
}
section.popup .popup-update-container form input:focus, section.popup .popup-update-container form textarea:focus {
  border-color: #007bff;
  outline: none;
}
section.popup .popup-update-container form textarea {
  resize: vertical;
}
section.popup .popup-update-container form input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: linen;
  color: black;
  font-size: large;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.popup .popup-update-container form input[type=submit]#valid:hover {
  transform: scale(1.1);
}
section.popup .popup-update-container form input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}
section.popup .popup-update-container form .popup-update-content-container {
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
section.popup .popup-update-container form .popup-update-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.popup .popup-update-container form .popup-update-content-container label:hover {
  transform: scale(1.1);
}
section.popup .popup-update-container form .popup-footer {
  display: flex;
}
section.popup .popup-update-container form .popup-footer .popup-delete-button {
  margin: 20px 0 20px 0;
  background-color: rgb(175, 33, 33);
  color: linen;
  font-size: large;
  border: 2px solid rgb(175, 33, 33);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.popup .popup-update-container form .popup-footer .popup-delete-button:hover {
  background-color: rgb(255, 0, 0);
  border-color: rgb(255, 0, 0);
  transform: scale(1.1);
}
section.popup .popup-update-container section {
  margin-bottom: 30px;
}
section.popup .popup-update-container section h3 {
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.5rem;
}
section.popup .popup-update-container section .form-row {
  margin-bottom: 20px;
}

section.admin-album form.form-admin-update-album .popup-update-content-container .label-input {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
}
section.admin-album form.form-admin-update-album .popup-update-content-container .label-input label {
  width: 90%;
}
section.admin-album form.form-admin-update-album section.new-music button {
  background: linen;
  border: 2px solid black;
  transition: 0.2s ease;
}
section.admin-album form.form-admin-update-album section.new-music button:hover {
  background-color: linen;
}
section.admin-album form.form-admin-update-album section.new-music .info-music {
  gap: 70px;
  margin-left: 120px;
}
section.admin-album form.form-admin-update-album section.new-music .info-music .label-input {
  margin-bottom: 20px;
}
section.admin-album form.form-admin-update-album section.new-music .info-music .button-label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-album form.form-admin-update-album section.new-music .info-music .button-label:hover {
  transform: scale(1.1);
}
section.admin-album form.form-admin-update-album section.new-music .info-music input {
  width: 100%;
}

form.form-admin-new-magazine .label-info-magazine-container, form.form-admin-update-magazine .label-info-magazine-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-height: 70px;
  margin-bottom: 40px;
}
form.form-admin-new-magazine .label-info-magazine-container input, form.form-admin-update-magazine .label-info-magazine-container input {
  width: 23%;
  height: 30px;
}
form.form-admin-new-magazine .magazine-image h3, form.form-admin-update-magazine .magazine-image h3 {
  font-size: 1.5rem;
  margin: 23px 0 15px 0;
  text-align: center;
}
form.form-admin-new-magazine .magazine-image .magazine-content-container, form.form-admin-update-magazine .magazine-image .magazine-content-container {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
form.form-admin-new-magazine .magazine-image .magazine-content-container label, form.form-admin-new-magazine .magazine-image .magazine-content-container .button-pictures, form.form-admin-update-magazine .magazine-image .magazine-content-container label, form.form-admin-update-magazine .magazine-image .magazine-content-container .button-pictures {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: linen;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
form.form-admin-new-magazine .magazine-image .magazine-content-container label:hover, form.form-admin-new-magazine .magazine-image .magazine-content-container .button-pictures:hover, form.form-admin-update-magazine .magazine-image .magazine-content-container label:hover, form.form-admin-update-magazine .magazine-image .magazine-content-container .button-pictures:hover {
  transform: scale(1.1);
}
form.form-admin-new-magazine .uploaded-files-container, form.form-admin-update-magazine .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
form.form-admin-new-magazine .uploaded-files-container legend, form.form-admin-update-magazine .uploaded-files-container legend {
  margin-left: 20px;
  color: black;
  font-size: 1.3rem;
}
form.form-admin-new-magazine .uploaded-files-container .uploaded-files, form.form-admin-update-magazine .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
form.form-admin-new-magazine .uploaded-files-container .uploaded-files i, form.form-admin-update-magazine .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
form.form-admin-new-magazine .uploaded-files-container .uploaded-files i:hover, form.form-admin-update-magazine .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
form.form-admin-new-magazine .uploaded-files-container .uploaded-files .picture-new-partner-container, form.form-admin-update-magazine .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
form.form-admin-new-magazine .uploaded-files-container .uploaded-files .picture-new-partner-container img, form.form-admin-update-magazine .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}

form .update-message-setup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
form .update-message-setup h3 {
  text-align: center;
}
form .update-message-setup .info-update-message,
form .update-message-setup .city-update-message,
form .update-message-setup .links-section {
  flex: 1 1 30%;
  min-width: 300px;
}
form .update-message-setup input {
  width: 90%;
  height: 15%;
  margin: 5px auto;
}
form .update-message-setup .info-update-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
form .update-message-setup .info-update-message label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
form .update-message-setup .info-update-message p {
  text-align: center;
}
form .update-message-setup .links-section {
  margin-left: 20px;
}
form .update-message-setup .links-section label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
form .description-update-message {
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
form .description-update-message label {
  margin-bottom: 5px;
  cursor: default;
  background: none;
  border: none;
  color: black;
}
form .description-update-message label:hover {
  transform: none;
}
form .description-update-message textarea {
  width: 100%;
  height: 100%;
}
form .uploaded-logo {
  margin-left: 35px;
}
form .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
form .uploaded-files-container legend {
  margin-left: 20px;
  color: black;
  font-size: 1.3rem;
}
form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
form .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
form .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
form .uploaded-files-container .uploaded-files .video-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
form .uploaded-files-container .uploaded-files .video-new-partner-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}

section.widget-pages-jaunes .backoffice-content-container {
  background: none;
  border: none;
}
section.widget-pages-jaunes .backoffice-content-container h2 {
  background-color: rgba(0, 0, 0, 0.85);
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes {
  color: black;
  font-size: 10px !important;
  font-family: "Open Sans";
  background-color: linen;
  width: 95%;
  margin: 10px auto;
  border-radius: 20px;
  padding: 1em 0.7em 8em 0.7em;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes * {
  box-sizing: border-box;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes h1, section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes h2, section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes h3 {
  background: none;
  font-family: "Open Sans Bold";
  margin: 0;
  padding: 0;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes h1 {
  font-size: 3.2em;
  margin-bottom: 25px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj_title_sub {
  font-size: 2em;
  margin-bottom: 48px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .title-form {
  font-size: 2.6em;
  padding: 20px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes p {
  font-size: 1.6em;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes > p {
  margin-bottom: 45px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .logo-top {
  margin-bottom: 60px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .logo-top img {
  width: 250px;
  max-width: 100%;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .logo-bottom {
  margin-top: 5em;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .logo-bottom img {
  width: 250px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search-wrapper {
  margin-bottom: 48px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search {
  box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.15);
  border-radius: 0.8em;
  overflow: hidden;
  margin-bottom: 28px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search form {
  background-color: #FFDE07;
  padding: 38px 20px 28px 20px;
  position: relative;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text],
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search button {
  vertical-align: bottom;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text] {
  border-radius: 42px;
  background-color: linen;
  line-height: 40px;
  max-height: 42px;
  width: calc(100% - 2px);
  border: solid 1px #D1D4D7;
  margin-right: -45px;
  font-size: 11px;
  padding: 10px 20px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].what, section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].where {
  border-radius: 42px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].what {
  margin-right: 0;
  margin-bottom: 10px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].where {
  padding-right: 50px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].pj-inputText {
  padding-right: 50px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search button {
  background-color: #64D6DF;
  border-radius: 100%;
  border: none;
  overflow: hidden;
  height: calc(42px - 2px);
  width: calc(42px - 2px);
  position: absolute;
  right: 23px;
  bottom: 29px;
  padding: 0;
  transition: 0.2s;
  box-shadow: -1.86364px 0px 3.72727px rgba(0, 0, 0, 0.12);
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search button .value {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search button:before {
  content: "";
  height: calc(42px - 2px);
  width: calc(42px - 2px);
  text-indent: 0px;
  background-image: url("../assets/images/loupe.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes {
  padding: 1em 3em 8em 4em;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .title-form {
  font-size: 2.6em;
  padding: 20px 32px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search form {
  padding: 38px 32px 28px 32px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search form:after {
  content: "";
  clear: both;
  display: table;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text] {
  padding: 10px 20px;
  float: left;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].what, section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].where {
  border-radius: 42px 0 0 42px;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].what {
  width: calc(40% - 2px);
  margin-bottom: 0;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search input[type=text].where {
  width: calc(60% - 2px);
  border-radius: 0 42px 42px 0;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search button {
  right: 36px;
  top: 39px;
  bottom: inherit;
}
section.widget-pages-jaunes .backoffice-content-container section.pages-jaunes .pj-search button:hover {
  background-color: rgb(42.1818181818, 192.4545454545, 204.3181818182);
  transition: 0.2s;
}

section.home {
  margin-top: 100px;
}
@media (max-width: 768px) {
  section.home {
    margin-top: 8vh;
  }
}
section.home nav.nav-categories ul {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  section.home nav.nav-categories ul {
    grid-template-columns: 1fr 1fr;
  }
}
section.home nav.nav-categories ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  section.home nav.nav-categories ul li {
    margin: 10px 20px;
  }
}
section.home nav.nav-categories ul li a {
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  color: #e2b259;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  section.home nav.nav-categories ul li a {
    font-size: 20px;
  }
}
section.home nav.nav-categories ul li a:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
section.home nav.nav-categories ul li a i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  section.home nav.nav-categories ul li a i {
    font-size: 3rem;
  }
}
section.home nav.nav-categories ul li a .bi {
  font-size: 3.5rem;
}
@media (max-width: 768px) {
  section.home nav.nav-categories ul li a .bi {
    font-size: 2rem;
  }
}

.frontoffice-container {
  background: black;
  min-height: 100vh;
}
.frontoffice-container header {
  position: relative;
  z-index: 99;
}
.frontoffice-container h1 {
  color: linen;
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 768px) {
  .frontoffice-container h1 {
    font-size: 1.5rem;
  }
}

.backoffice-container {
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 100vh;
}
.backoffice-container .admin-content-container {
  background-color: rgba(0, 0, 0, 0.85);
  color: linen;
  border: 2px solid linen;
  border-radius: 20px;
  width: 90%;
  margin: 10px auto;
}
.backoffice-container header {
  position: relative;
  z-index: 999;
}
.backoffice-container .backoffice-content-container {
  width: 90%;
  margin: 10px auto;
  color: linen;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 20px;
  border: 2px solid linen;
}
.backoffice-container .backoffice-content-container .backoffice-content h2 {
  font-size: 2rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
.backoffice-container .backoffice-content-container .backoffice-content input[type=submit] {
  margin: 20px auto;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
.backoffice-container .backoffice-content-container .backoffice-content input[type=submit]:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .backoffice-container .backoffice-content-container {
    border-radius: 0;
    width: 100%;
    margin: 5px auto;
    border-left: none;
    border-right: none;
  }
  .backoffice-container .backoffice-content-container .backoffice-content h2 {
    font-size: 1.2rem;
    margin: 20px auto 5px auto;
    padding: 5px;
  }
  .backoffice-container .backoffice-content-container .backoffice-content input[type=submit] {
    font-size: medium;
    padding: 5px;
  }
}
.backoffice-container .modal-bigger-picture {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.backoffice-container .modal-bigger-picture .modal-content {
  max-width: 90%;
  max-height: 90%;
}
.backoffice-container .modal-bigger-picture .modal-content img {
  width: 100%;
  height: auto;
}

section.iframe-link .button-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
section.iframe-link .btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2b259;
  font-size: 1rem;
  padding: 10px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.iframe-link .iframe-container {
  width: 80%;
  margin: 10px auto 0;
}
@media (max-width: 768px) {
  section.iframe-link .iframe-container {
    width: 95%;
  }
}
section.iframe-link .iframe-container iframe {
  width: 100%;
  height: 85vh;
  border-radius: 20px;
  scrollbar-color: #e2b259;
}

section.social-media ul {
  margin: 50px auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  section.social-media ul {
    margin-top: 2rem;
    grid-template-columns: 1fr 1fr;
  }
}
section.social-media ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  section.social-media ul li {
    margin: 10px 20px;
  }
}
section.social-media ul li a {
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  color: #e2b259;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  section.social-media ul li a {
    font-size: 20px;
  }
}
section.social-media ul li a:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
section.social-media ul li a i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  section.social-media ul li a i {
    font-size: 3rem;
  }
}
section.social-media p {
  text-align: center;
  font-size: 1.6rem;
  color: linen;
  padding: 20px;
  margin-top: 300px;
  background: rgba(0, 0, 0, 0.75);
}

section.partners-list {
  width: 70%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  section.partners-list {
    width: 80%;
  }
}
@media (max-width: 768px) {
  section.partners-list {
    width: 95%;
  }
}

section.partner img {
  display: block;
  max-width: 90%;
  max-height: 30vh;
  margin: 0 auto;
  padding: 10px;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: linen;
}
section.partner .text-content-partner-container {
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  border-top: 2px solid #e2b259;
  border-bottom: 2px solid #e2b259;
  text-align: center;
}
@media (max-width: 768px) {
  section.partner .text-content-partner-container {
    padding: 10px;
  }
}
section.partner .text-content-partner-container h1 {
  font-size: 4rem;
}
@media (max-width: 768px) {
  section.partner .text-content-partner-container h1 {
    font-size: 1.5rem;
  }
}
section.partner .text-content-partner-container a {
  color: #e2b259;
  text-decoration: underline;
  font-style: italic;
}
section.partner .text-content-partner-container a:hover {
  color: #b1852d;
}
section.partner .text-content-partner-container p {
  color: white;
  margin: 20px auto;
  max-width: 70%;
}
@media (max-width: 768px) {
  section.partner .text-content-partner-container p {
    max-width: 90%;
  }
}
section.partner .text-content-partner-container .btn-interview {
  display: block;
  width: 70%;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #e2b259;
  color: black;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}
section.partner .text-content-partner-container .btn-interview:hover {
  background-color: red;
}
section.partner .content-uploads-partner-container {
  text-align: center;
}
section.partner .content-uploads-partner-container img {
  max-width: 90%;
  height: auto;
  max-height: 50vh;
  margin: 20px auto;
  padding: 0;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: linen;
}
section.partner .content-uploads-partner-container video {
  max-width: 90%;
  height: auto;
  max-height: 50vh;
  margin: 20px auto;
  padding: 0;
  border: 2px solid #e2b259;
  border-radius: 20px;
  background-color: linen;
}
section.partner .contact-partner-container {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 10px;
  border-top: 2px solid #e2b259;
}
section.partner .contact-partner-container .info-contact-partner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}
section.partner .contact-partner-container .info-contact-partner-container a {
  flex: 0 1 calc(16.66% - 20px);
  margin: 10px;
  box-sizing: border-box;
  color: #e2b259;
  font-size: 3rem;
  display: flex;
  align-items: center;
  transition: 0.2s ease;
}
section.partner .contact-partner-container .info-contact-partner-container a:hover {
  color: #b1852d;
}
@media (max-width: 768px) {
  section.partner .contact-partner-container .info-contact-partner-container a {
    font-size: 1.9rem;
    flex: 0 1 calc(33.33% - 20px);
  }
}
section.partner .contact-partner-container .info-contact-partner-container a img {
  border: none;
  background: none;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  section.partner .contact-partner-container .info-contact-partner-container a img {
    max-width: 25px;
    min-width: 25px;
    min-height: 25px;
    max-height: 25px;
  }
}

section.home-redirect .backoffice-content-container {
  background: none;
  border: none;
}
section.home-redirect .backoffice-content-container .account-user-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
section.home-redirect .backoffice-content-container .account-user-content button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 20px;
  color: #e2b259;
  font-size: 1.5rem;
  transition: 0.3s ease;
}
section.home-redirect .backoffice-content-container .account-user-content button:hover {
  border: 2px solid #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  color: #b1852d;
  transform: scale(1.05);
}
section.home-redirect .backoffice-content-container section.city-selector .city-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
  section.home-redirect .backoffice-content-container section.city-selector .city-display {
    grid-template-columns: 1fr;
  }
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .button-cities-container .errorPosition {
  color: white;
  background-color: red;
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .button-cities-container .problemPosition {
  color: white;
  background-color: orange;
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .button-cities-container p {
  margin: 20px auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 88%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid white;
  border-radius: 20px;
  color: #e2b259;
  font-size: 1.5rem;
  transition: 0.3s ease;
  text-align: center;
}
@media (max-width: 768px) {
  section.home-redirect .backoffice-content-container section.city-selector .city-display .button-cities-container p {
    width: 70%;
  }
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .other-cities {
  margin-top: 20px;
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .other-cities .button-cities-container {
  display: block;
  margin: 0 auto;
  width: 50%;
  height: 88%;
}
@media (max-width: 768px) {
  section.home-redirect .backoffice-content-container section.city-selector .city-display .other-cities .button-cities-container {
    width: 70%;
  }
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .other-cities .button-cities-container button {
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 20px;
  color: #e2b259;
  font-size: 1.5rem;
  transition: 0.3s ease;
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .other-cities .button-cities-container button i {
  font-size: 4rem;
  margin-bottom: 10px;
  color: linen;
}
@media (max-width: 768px) {
  section.home-redirect .backoffice-content-container section.city-selector .city-display .other-cities .button-cities-container button i {
    margin-top: 10px;
    font-size: 3rem;
  }
}
section.home-redirect .backoffice-content-container section.city-selector .city-display .other-cities .button-cities-container button:hover {
  border: 2px solid #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  color: #b1852d;
  transform: scale(1.05);
}

section.cities-list .backoffice-content-container {
  background: none;
  border: none;
}
section.cities-list .backoffice-content-container .city-searchbar-container {
  color: black;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  background-color: #e2b259;
  border-radius: 20px;
  border: 2px solid linen;
}
@media (max-width: 768px) {
  section.cities-list .backoffice-content-container .city-searchbar-container {
    padding: 5px;
    width: 80%;
  }
}
section.cities-list .backoffice-content-container .city-searchbar-container h1 {
  font-size: 2rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  section.cities-list .backoffice-content-container .city-searchbar-container h1 {
    font-size: 1.5rem;
  }
}
section.cities-list .backoffice-content-container .city-searchbar-container form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
@media (max-width: 768px) {
  section.cities-list .backoffice-content-container .city-searchbar-container form {
    flex-direction: column;
  }
}
section.cities-list .backoffice-content-container .city-searchbar-container form input {
  flex: 1;
  height: 40px;
  font-size: large;
}
@media (max-width: 768px) {
  section.cities-list .backoffice-content-container .city-searchbar-container form input {
    width: 90%;
    font-size: 0.8rem;
  }
}
section.cities-list .backoffice-content-container .city-display {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  margin: 30px;
  gap: 20px;
}
@media (max-width: 768px) {
  section.cities-list .backoffice-content-container .city-display {
    grid-template-columns: 1fr;
  }
}
section.cities-list .backoffice-content-container .city-display .load-more-cities {
  margin-bottom: 40px;
}
section.cities-list .backoffice-content-container .city-display .load-more-cities .load-more-cities-button {
  padding: 10px;
  margin: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 20px;
  color: #e2b259;
  font-size: 1.5rem;
  transition: 0.3s ease;
}
@media (max-width: 768px) {
  section.cities-list .backoffice-content-container .city-display .load-more-cities .load-more-cities-button {
    width: 80%;
    margin-bottom: 30px;
  }
}
section.cities-list .backoffice-content-container .city-display .load-more-cities .load-more-cities-button i {
  font-size: 4rem;
  margin-bottom: 10px;
  color: linen;
}
@media (max-width: 768px) {
  section.cities-list .backoffice-content-container .city-display .load-more-cities .load-more-cities-button i {
    margin-top: 10px;
    font-size: 3rem;
  }
}
section.cities-list .backoffice-content-container .city-display .load-more-cities .load-more-cities-button:hover {
  border: 2px solid #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  color: #b1852d;
  transform: scale(1.05);
}

.connexion, .forget-password, .reset-password {
  width: 100%;
}
.connexion form.form-connexion, .connexion form.form-forget-password, .connexion form.form-reset-password, .forget-password form.form-connexion, .forget-password form.form-forget-password, .forget-password form.form-reset-password, .reset-password form.form-connexion, .reset-password form.form-forget-password, .reset-password form.form-reset-password {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.connexion form.form-connexion input, .connexion form.form-forget-password input, .connexion form.form-reset-password input, .forget-password form.form-connexion input, .forget-password form.form-forget-password input, .forget-password form.form-reset-password input, .reset-password form.form-connexion input, .reset-password form.form-forget-password input, .reset-password form.form-reset-password input {
  font-size: large;
  margin: 20px 0;
  padding: 5px;
  width: 100%;
  height: 40px;
  background-color: linen;
  border: 2px solid #b1852d;
  border-radius: 10px;
}
.connexion form.form-connexion input::placeholder, .connexion form.form-forget-password input::placeholder, .connexion form.form-reset-password input::placeholder, .forget-password form.form-connexion input::placeholder, .forget-password form.form-forget-password input::placeholder, .forget-password form.form-reset-password input::placeholder, .reset-password form.form-connexion input::placeholder, .reset-password form.form-forget-password input::placeholder, .reset-password form.form-reset-password input::placeholder {
  color: black;
}
.connexion form.form-connexion input:focus, .connexion form.form-forget-password input:focus, .connexion form.form-reset-password input:focus, .forget-password form.form-connexion input:focus, .forget-password form.form-forget-password input:focus, .forget-password form.form-reset-password input:focus, .reset-password form.form-connexion input:focus, .reset-password form.form-forget-password input:focus, .reset-password form.form-reset-password input:focus {
  outline: none;
  border: 2px solid black;
}
.connexion .form-links, .forget-password .form-links, .reset-password .form-links {
  display: flex;
  justify-content: space-between;
  margin: 0 20%;
}
.connexion .form-links p, .forget-password .form-links p, .reset-password .form-links p {
  color: #b1852d;
  cursor: pointer;
  text-decoration: underline;
}
.connexion .form-links p:hover, .forget-password .form-links p:hover, .reset-password .form-links p:hover {
  color: #e2b259;
}
@media (max-width: 768px) {
  .connexion .form-links, .forget-password .form-links, .reset-password .form-links {
    margin: 0 10px;
  }
}

section.streets-index .streets-index-content {
  background: rgba(0, 0, 0, 0.75);
  width: 90%;
  margin: 3vh auto;
  padding: 20px;
  border-radius: 30px;
  border: 2px solid linen;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content {
    width: 95%;
    padding: 5px;
  }
}
section.streets-index .streets-index-content form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 20px 0;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content form {
    flex-direction: column;
    gap: 10px;
  }
}
section.streets-index .streets-index-content form label {
  font-size: 1.5rem;
  color: #e2b259;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content form label {
    font-size: 1.2rem;
  }
}
section.streets-index .streets-index-content form input {
  width: 300px;
  height: 50px;
  font-size: 1.5rem;
  padding: 0 10px;
  border: 2px solid linen;
  border-radius: 10px;
  background: #e2b259;
  color: linen;
  transition: 0.3s ease;
}
section.streets-index .streets-index-content form input::placeholder {
  color: linen;
}
section.streets-index .streets-index-content form input:focus {
  outline: none;
  background: #b1852d;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content form input {
    width: 85%;
    height: 40px;
    font-size: 1.2rem;
  }
}
section.streets-index .streets-index-content .results-streets {
  text-align: center;
}
section.streets-index .streets-index-content .results-streets h2 {
  font-size: 2rem;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content .results-streets h2 {
    font-size: 1.5rem;
  }
}
section.streets-index .streets-index-content .results-streets ul {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content .results-streets ul {
    width: 97%;
    gap: 10px;
  }
}
section.streets-index .streets-index-content .results-streets ul li {
  text-align: center;
  width: 50%;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content .results-streets ul li {
    width: 90%;
  }
}
section.streets-index .streets-index-content .results-streets ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e2b259;
  color: black;
  height: 100%;
  width: 100%;
  min-height: 60px;
  border: 2px solid linen;
  border-radius: 20px;
  transition: 0.2s ease;
}
section.streets-index .streets-index-content .results-streets ul li a:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.streets-index .streets-index-content .results-streets ul li p {
  font-size: 1.5rem;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 20px;
  color: black;
}
@media (max-width: 768px) {
  section.streets-index .streets-index-content .results-streets ul li p {
    font-size: 1.2rem;
  }
}
section.streets-index section.iframe-container {
  text-align: center;
}
section.streets-index section.iframe-container iframe {
  width: 90%;
  height: 500px;
  border: 2px solid linen;
  border-radius: 20px;
}

section.new-account-user, section.admin-new-user {
  margin-bottom: 40px;
}
section.new-account-user form.form-admin-new-user, section.new-account-user form.form-new-account-user, section.admin-new-user form.form-admin-new-user, section.admin-new-user form.form-new-account-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
section.new-account-user form.form-admin-new-user h3, section.new-account-user form.form-new-account-user h3, section.admin-new-user form.form-admin-new-user h3, section.admin-new-user form.form-new-account-user h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #e2b259;
}
section.new-account-user form.form-admin-new-user .info-new-user.center-content, section.new-account-user form.form-new-account-user .info-new-user.center-content, section.admin-new-user form.form-admin-new-user .info-new-user.center-content, section.admin-new-user form.form-new-account-user .info-new-user.center-content {
  justify-content: center;
}
section.new-account-user form.form-admin-new-user .info-new-user.center-content .info-personnels-new-user, section.new-account-user form.form-new-account-user .info-new-user.center-content .info-personnels-new-user, section.admin-new-user form.form-admin-new-user .info-new-user.center-content .info-personnels-new-user, section.admin-new-user form.form-new-account-user .info-new-user.center-content .info-personnels-new-user {
  width: 60%;
}
@media (max-width: 768px) {
  section.new-account-user form.form-admin-new-user .info-new-user.center-content, section.new-account-user form.form-new-account-user .info-new-user.center-content, section.admin-new-user form.form-admin-new-user .info-new-user.center-content, section.admin-new-user form.form-new-account-user .info-new-user.center-content {
    justify-content: center;
  }
  section.new-account-user form.form-admin-new-user .info-new-user.center-content .info-personnels-new-user, section.new-account-user form.form-new-account-user .info-new-user.center-content .info-personnels-new-user, section.admin-new-user form.form-admin-new-user .info-new-user.center-content .info-personnels-new-user, section.admin-new-user form.form-new-account-user .info-new-user.center-content .info-personnels-new-user {
    width: 90%;
  }
}
section.new-account-user form.form-admin-new-user .info-new-user, section.new-account-user form.form-new-account-user .info-new-user, section.admin-new-user form.form-admin-new-user .info-new-user, section.admin-new-user form.form-new-account-user .info-new-user {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user {
  width: 48%;
  display: flex;
  flex-direction: column;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user input,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user input, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user input,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user input, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user input,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user input, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user input,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user input {
  width: 100%;
  min-height: 40px;
  margin: 10px 0;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user input::placeholder,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user input::placeholder, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user input::placeholder,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user input::placeholder, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user input::placeholder,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user input::placeholder, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user input::placeholder,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user input::placeholder {
  color: black;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user input:hover,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user input:hover, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user input:hover,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user input:hover, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user input:hover,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user input:hover, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user input:hover,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user input:hover {
  background: #e2b259;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user input:focus,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user input:focus, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user input:focus,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user input:focus, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user input:focus,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user input:focus, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user input:focus,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user input:focus {
  background: #e2b259;
  outline: none;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user .uploaded-logo,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user .uploaded-logo, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user .uploaded-logo,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user .uploaded-logo, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user .uploaded-logo,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user .uploaded-logo, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user .uploaded-logo,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user .uploaded-logo {
  margin-top: 20px;
  text-align: center;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user .uploaded-logo img,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user .uploaded-logo img, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user .uploaded-logo img,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user .uploaded-logo img, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user .uploaded-logo img,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user .uploaded-logo img, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user .uploaded-logo img,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user .uploaded-logo img {
  max-width: 100%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 4px;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user .button-label,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user .button-label, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user .button-label,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user .button-label, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user .button-label,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user .button-label, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user .button-label,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user .button-label {
  display: flex;
  padding: 10px;
  margin: 10px auto;
  background-color: #e2b259;
  color: black;
  border: 2px solid linen;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user .button-label:hover,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user .button-label:hover, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user .button-label:hover,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user .button-label:hover, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user .button-label:hover,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user .button-label:hover, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user .button-label:hover,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user .button-label:hover {
  background-color: #b1852d;
}
section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user .hide-on-large,
section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user .hide-on-large, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user .hide-on-large,
section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user .hide-on-large, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user .hide-on-large,
section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user .hide-on-large, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user .hide-on-large,
section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user .hide-on-large {
  display: none;
}
@media (max-width: 768px) {
  section.new-account-user form.form-admin-new-user .info-new-user .info-personnels-new-user .hide-on-large,
  section.new-account-user form.form-admin-new-user .info-new-user .info-professionels-new-user .hide-on-large, section.new-account-user form.form-new-account-user .info-new-user .info-personnels-new-user .hide-on-large,
  section.new-account-user form.form-new-account-user .info-new-user .info-professionels-new-user .hide-on-large, section.admin-new-user form.form-admin-new-user .info-new-user .info-personnels-new-user .hide-on-large,
  section.admin-new-user form.form-admin-new-user .info-new-user .info-professionels-new-user .hide-on-large, section.admin-new-user form.form-new-account-user .info-new-user .info-personnels-new-user .hide-on-large,
  section.admin-new-user form.form-new-account-user .info-new-user .info-professionels-new-user .hide-on-large {
    display: block;
    margin: 30px auto;
  }
}
section.new-account-user form.form-admin-new-user .general-condition, section.new-account-user form.form-new-account-user .general-condition, section.admin-new-user form.form-admin-new-user .general-condition, section.admin-new-user form.form-new-account-user .general-condition {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
section.new-account-user form.form-admin-new-user .general-condition .general-condition-check-box-label, section.new-account-user form.form-new-account-user .general-condition .general-condition-check-box-label, section.admin-new-user form.form-admin-new-user .general-condition .general-condition-check-box-label, section.admin-new-user form.form-new-account-user .general-condition .general-condition-check-box-label {
  display: flex;
}
section.new-account-user form.form-admin-new-user .general-condition .general-condition-check-box-label p, section.new-account-user form.form-new-account-user .general-condition .general-condition-check-box-label p, section.admin-new-user form.form-admin-new-user .general-condition .general-condition-check-box-label p, section.admin-new-user form.form-new-account-user .general-condition .general-condition-check-box-label p {
  margin-top: 30px;
}
@media (max-width: 768px) {
  section.new-account-user form, section.admin-new-user form {
    padding: 10px;
  }
  section.new-account-user form .info-new-user, section.admin-new-user form .info-new-user {
    flex-direction: column;
    align-items: center;
  }
  section.new-account-user form .info-new-user .info-personnels-new-user,
  section.new-account-user form .info-new-user .info-professionels-new-user, section.admin-new-user form .info-new-user .info-personnels-new-user,
  section.admin-new-user form .info-new-user .info-professionels-new-user {
    width: 90%;
  }
}

button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 7%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
button.btn-refresh:hover {
  background: #b1852d;
}
button.btn-refresh i {
  font-size: 1.5rem;
}

section.admin-cities section.admin-new-cities {
  margin-bottom: 40px;
}
section.admin-cities section.admin-new-cities form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
section.admin-cities section.admin-new-cities form h3 {
  text-align: center;
}
section.admin-cities section.admin-new-cities form .info-new-city {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
section.admin-cities section.admin-new-cities form .info-new-city label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-cities section.admin-new-cities form .info-new-city input {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-cities section.admin-new-cities form .info-new-city input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-cities section.admin-new-cities form .info-new-city input:hover {
  background: #b1852d;
}
section.admin-cities section.admin-new-cities form .info-new-city input:focus {
  background: #b1852d;
}
section.admin-cities section.admin-new-cities form .files-upload .input-container-uploads {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
}
section.admin-cities section.admin-new-cities form .files-upload .input-container-uploads div {
  display: flex;
  flex-direction: column;
}
section.admin-cities section.admin-new-cities form .files-upload .input-container-uploads div label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-cities section.admin-new-cities form .files-upload .input-container-uploads div label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.admin-cities section.admin-new-cities form .files-upload .render-uploads-new-city {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
}
section.admin-cities section.admin-new-cities form .files-upload .render-uploads-new-city img {
  max-height: 100px;
  max-width: 200px;
  margin: 5px;
}
section.admin-cities section.admin-new-cities form .social-media-new-city {
  display: grid;
}
section.admin-cities section.admin-new-cities form .social-media-new-city label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-cities section.admin-new-cities form .social-media-new-city input {
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-cities section.admin-new-cities form .social-media-new-city input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-cities section.admin-new-cities form .social-media-new-city input:hover {
  background: #b1852d;
}
section.admin-cities section.admin-new-cities form .social-media-new-city input:focus {
  background: #b1852d;
}
section.admin-cities section.admin-new-cities form input[type=submit] {
  grid-column: 2/3;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-cities section.admin-new-cities form input[type=submit]:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
section.admin-cities section.city-management form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
}
section.admin-cities section.city-management form .name-select-city {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.admin-cities section.city-management form .name-select-city input {
  margin-left: 30px;
  width: 70%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-cities section.city-management form .name-select-city input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-cities section.city-management form .name-select-city input:hover {
  background: #b1852d;
}
section.admin-cities section.city-management form .name-select-city input:focus {
  background: #b1852d;
}
section.admin-cities section.city-management form .position-select {
  display: flex;
}
section.admin-cities section.city-management form .position-select .country-select-city {
  margin-top: 20px;
  height: 120px;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
}
section.admin-cities section.city-management form .position-select .country-select-city select {
  flex: 1;
  margin: 20px 0;
  padding: 5px;
  font-size: 1rem;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
  color: black;
}
section.admin-cities section.city-management form .position-select .country-select-city select:hover {
  border: 2px solid rgb(186, 186, 186);
}
section.admin-cities section.city-management form .position-select .postalcode-select-city {
  margin: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.admin-cities section.city-management form .position-select .postalcode-select-city input {
  margin: 30px;
  width: 100%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-cities section.city-management form .position-select .postalcode-select-city input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-cities section.city-management form .position-select .postalcode-select-city input:hover {
  background: #b1852d;
}
section.admin-cities section.city-management form .position-select .postalcode-select-city input:focus {
  background: #b1852d;
}
section.admin-cities section.city-management p {
  margin: 20px 0;
  text-align: center;
  font-size: 1.5rem;
}
section.admin-cities section.city-management table {
  color: black;
  background: #e2b259;
}
section.admin-cities section.city-management button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 7%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-cities section.city-management button.btn-refresh:hover {
  background: #b1852d;
}
section.admin-cities section.city-management button.btn-refresh i {
  font-size: 1.5rem;
}

section.admin-partner section.new-partner {
  margin-bottom: 40px;
}
section.admin-partner section.new-partner form .new-partner-setup {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
section.admin-partner section.new-partner form .new-partner-setup h3 {
  text-align: center;
}
section.admin-partner section.new-partner form .new-partner-setup .info-new-partner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
section.admin-partner section.new-partner form .new-partner-setup .info-new-partner label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-partner section.new-partner form .new-partner-setup .info-new-partner p {
  text-align: center;
}
section.admin-partner section.new-partner form .new-partner-setup .info-new-partner input {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-setup .info-new-partner input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-partner section.new-partner form .new-partner-setup .info-new-partner input:hover {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .info-new-partner input:focus {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner h3 {
  margin-bottom: 20px;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info input {
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info input:hover {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info input:focus {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info .without-coordinates {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info .without-coordinates #btn-coordinates-activaction {
  text-align: center;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info .without-coordinates #btn-coordinates-activaction:hover {
  background-color: #b1852d;
  transform: scale(0.97);
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info .with-coordinates .coordinates-city {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10px;
}
section.admin-partner section.new-partner form .new-partner-setup .city-new-partner section.location-info .with-coordinates .coordinates-city input {
  grid-column: 2/4;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner h3 {
  margin: 40px 0 10px 0;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner label[for=interview-photo-content] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner label[for=interview-photo-content]:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner input, section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner textarea {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner input::placeholder, section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner textarea::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner input:hover, section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner textarea:hover {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner input:focus, section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner textarea:focus {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .interview-new-partner textarea {
  resize: none;
  height: auto;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner h3 {
  margin-bottom: 20px;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .flash-message {
  background: red;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid linen;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner label {
  cursor: default;
  background: none;
  border: none;
  color: linen;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner label:hover {
  transform: none;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea:hover {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .categories-new-partner .description-new-partner textarea:focus {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .social-media-new-partner {
  display: grid;
}
section.admin-partner section.new-partner form .new-partner-setup .social-media-new-partner label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-partner section.new-partner form .new-partner-setup .social-media-new-partner input {
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-setup .social-media-new-partner input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-partner section.new-partner form .new-partner-setup .social-media-new-partner input:hover {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-setup .social-media-new-partner input:focus {
  background: #b1852d;
}
section.admin-partner section.new-partner form .new-partner-content h3 {
  text-align: center;
}
section.admin-partner section.new-partner form .new-partner-content .new-partner-content-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
section.admin-partner section.new-partner form .new-partner-content .new-partner-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .new-partner-content .new-partner-content-container label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.admin-partner section.new-partner form .logo-interview {
  display: flex;
}
section.admin-partner section.new-partner form .logo-interview .uploaded-logo {
  margin-left: 35px;
}
section.admin-partner section.new-partner form .logo-interview .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
section.admin-partner section.new-partner form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
section.admin-partner section.new-partner form .uploaded-files-container legend {
  margin-left: 20px;
  color: #e2b259;
  font-size: 1.3rem;
}
section.admin-partner section.new-partner form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
section.admin-partner section.new-partner form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
section.admin-partner section.new-partner form .uploaded-files-container .uploaded-files .picture-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
section.admin-partner section.new-partner form .uploaded-files-container .uploaded-files .picture-new-partner-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
section.admin-partner section.new-partner form .uploaded-files-container .uploaded-files .video-new-partner-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
section.admin-partner section.new-partner form .uploaded-files-container .uploaded-files .video-new-partner-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}
section.admin-partner section.new-partner .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
section.admin-partner section.new-partner .modal-content {
  background: #e2b259;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 800px;
  border: 2px solid linen;
  color: black;
}
section.admin-partner section.new-partner .modal-content button {
  padding: 10px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-partner section.new-partner .modal-content button:hover {
  background: #b1852d;
}
section.admin-partner section.new-partner .modal-content .modal-close {
  position: absolute;
  padding: 5px;
  top: 5px;
  right: 10px;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
section.admin-partner section.new-partner .modal-content .modal-button-container {
  display: flex;
  justify-content: center;
}
section.admin-partner section.category-management table {
  width: 70%;
}
section.admin-partner section.category-management button.btn-refresh {
  right: 20%;
}
section.admin-partner section.category-management div {
  text-align: center;
}
section.admin-partner section.category-management div input {
  width: 30%;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-partner section.category-management div input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-partner section.category-management div input:hover {
  background: #b1852d;
}
section.admin-partner section.category-management div input:focus {
  background: #b1852d;
}
section.admin-partner section.category-management div button {
  padding: 5px;
  margin: 5px;
  color: black;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
section.admin-partner section.category-management div button:hover {
  background-color: #b1852d;
  color: linen;
}
section.admin-partner section.category-management div button#disabledCreateCat {
  cursor: not-allowed;
  background-color: black;
  color: linen;
}
section.admin-partner section.partner-management form.form-admin-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
}
section.admin-partner section.partner-management form.form-admin-partners .name-select-partner {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.admin-partner section.partner-management form.form-admin-partners .name-select-partner input {
  margin-left: 30px;
  width: 70%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-partner section.partner-management form.form-admin-partners .name-select-partner input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-partner section.partner-management form.form-admin-partners .name-select-partner input:hover {
  background: #b1852d;
}
section.admin-partner section.partner-management form.form-admin-partners .name-select-partner input:focus {
  background: #b1852d;
}
section.admin-partner section.partner-management form.form-admin-partners .others-select-options {
  margin-top: 20px;
  height: 120px;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
}
section.admin-partner section.partner-management form.form-admin-partners .others-select-options select {
  flex: 1;
  margin: 20px 0;
  padding: 5px;
  font-size: 1rem;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
  color: black;
}
section.admin-partner section.partner-management form.form-admin-partners .others-select-options select:hover {
  border: 2px solid rgb(186, 186, 186);
}
section.admin-partner section.partner-management button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 7%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-partner section.partner-management button.btn-refresh:hover {
  background: #b1852d;
}
section.admin-partner section.partner-management button.btn-refresh i {
  font-size: 1.5rem;
}
section.admin-partner section.partner-management p {
  margin: 20px 0;
  text-align: center;
  font-size: 1.5rem;
}
section.admin-partner section.partner-management div {
  text-align: center;
}
section.admin-partner section.partner-management div button {
  padding: 5px;
  margin: 5px;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
section.admin-partner section.partner-management div button:hover {
  background-color: #b1852d;
  color: linen;
}

section.admin-album .info-album {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 40px;
}
section.admin-album .info-album .label-input {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
section.admin-album .info-album .label-input label {
  margin-bottom: 10px;
}
section.admin-album .info-album .label-input #description {
  width: 100%;
  display: flex;
  flex-direction: column;
}
section.admin-album .info-album .label-input #description label {
  cursor: default;
  background: none;
  border: none;
  color: linen;
}
section.admin-album .info-album .label-input #description label:hover {
  transform: none;
}
section.admin-album .info-photo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 80px;
}
section.admin-album .info-photo .uploaded-logo {
  margin-top: 20px;
  text-align: center;
}
section.admin-album .info-photo .uploaded-logo img {
  max-width: 100%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 4px;
}
section.admin-album section.new-music {
  margin-top: 60px;
}
section.admin-album section.new-music h3 {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
section.admin-album section.new-music .title-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
section.admin-album section.new-music .title-delete h4 {
  margin: 0;
}
section.admin-album section.new-music .title-delete button {
  position: absolute;
  right: 5%;
  top: -70%;
  transform: translateY(-50%);
  background-color: rgb(175, 33, 33);
}
section.admin-album section.new-music .title-delete button:hover {
  background-color: rgb(255, 0, 0);
}
section.admin-album section.new-music .info-music {
  margin: 20px 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
section.admin-album section.new-music section.uploaded-audio {
  margin-top: 20px;
  text-align: center;
}
section.admin-album section.new-music button {
  display: flex;
  padding: 10px;
  margin: 10px auto;
  background-color: #e2b259;
  color: black;
  border: 2px solid linen;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 40px auto;
}
section.admin-album section.new-music button:hover {
  background-color: #b1852d;
}
section.admin-album section.new-album textarea {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-album section.new-album textarea::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-album section.new-album textarea:hover {
  background: #b1852d;
}
section.admin-album section.new-album textarea:focus {
  background: #b1852d;
}
section.admin-album section.new-album input {
  width: 100%;
  min-height: 40px;
  margin: 10px 0;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-album section.new-album input::placeholder {
  color: black;
}
section.admin-album section.new-album input:hover {
  background: #e2b259;
}
section.admin-album section.new-album input:focus {
  background: #e2b259;
  outline: none;
}
section.admin-album section.new-album .button-label {
  display: flex;
  padding: 10px;
  margin: 10px auto;
  background-color: #e2b259;
  color: black;
  border: 2px solid linen;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
section.admin-album section.new-album .button-label:hover {
  background-color: #b1852d;
}
section.admin-album section.new-album input[type=submit] {
  display: flex;
  width: 50%;
}

section.admin-messages section.new-message {
  margin-bottom: 40px;
}
section.admin-messages section.new-message form .new-message-setup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
section.admin-messages section.new-message form .new-message-setup h3 {
  text-align: center;
}
section.admin-messages section.new-message form .new-message-setup .info-new-message,
section.admin-messages section.new-message form .new-message-setup .city-new-message,
section.admin-messages section.new-message form .new-message-setup .links-section {
  flex: 1 1 30%;
  min-width: 300px;
}
section.admin-messages section.new-message form .new-message-setup .info-new-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
section.admin-messages section.new-message form .new-message-setup .info-new-message label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-messages section.new-message form .new-message-setup .info-new-message p {
  text-align: center;
}
section.admin-messages section.new-message form .new-message-setup .info-new-message input {
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-messages section.new-message form .new-message-setup .info-new-message input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-messages section.new-message form .new-message-setup .info-new-message input:hover {
  background: #b1852d;
}
section.admin-messages section.new-message form .new-message-setup .info-new-message input:focus {
  background: #b1852d;
}
section.admin-messages section.new-message form .new-message-setup .links-section {
  margin-left: 20px;
}
section.admin-messages section.new-message form .new-message-setup .links-section label {
  display: flex;
  align-items: flex-end;
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-messages section.new-message form .new-message-setup .links-section input {
  width: 90%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-messages section.new-message form .new-message-setup .links-section input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-messages section.new-message form .new-message-setup .links-section input:hover {
  background: #b1852d;
}
section.admin-messages section.new-message form .new-message-setup .links-section input:focus {
  background: #b1852d;
}
section.admin-messages section.new-message form .description-new-message {
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
section.admin-messages section.new-message form .description-new-message label {
  margin-bottom: 5px;
  cursor: default;
  background: none;
  border: none;
  color: linen;
}
section.admin-messages section.new-message form .description-new-message label:hover {
  transform: none;
}
section.admin-messages section.new-message form .description-new-message textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-messages section.new-message form .description-new-message textarea::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-messages section.new-message form .description-new-message textarea:hover {
  background: #b1852d;
}
section.admin-messages section.new-message form .description-new-message textarea:focus {
  background: #b1852d;
}
section.admin-messages section.new-message form .new-message-content h3 {
  text-align: center;
}
section.admin-messages section.new-message form .new-message-content .new-message-content-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
section.admin-messages section.new-message form .new-message-content .new-message-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-messages section.new-message form .new-message-content .new-message-content-container label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.admin-messages section.new-message form .uploaded-logo {
  margin-left: 35px;
}
section.admin-messages section.new-message form .uploaded-logo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
section.admin-messages section.new-message form .uploaded-files-container {
  width: 95%;
  margin: 0 auto;
  border: 2px solid linen;
  border-radius: 10px;
}
section.admin-messages section.new-message form .uploaded-files-container legend {
  margin-left: 20px;
  color: #e2b259;
  font-size: 1.3rem;
}
section.admin-messages section.new-message form .uploaded-files-container .uploaded-files {
  display: flex;
  flex-wrap: wrap;
}
section.admin-messages section.new-message form .uploaded-files-container .uploaded-files i {
  color: rgb(175, 33, 33);
  font-size: 2rem;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-messages section.new-message form .uploaded-files-container .uploaded-files i:hover {
  color: rgb(255, 0, 0);
  transform: scale(1.1);
}
section.admin-messages section.new-message form .uploaded-files-container .uploaded-files .picture-new-message-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
section.admin-messages section.new-message form .uploaded-files-container .uploaded-files .picture-new-message-container img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 10px;
}
section.admin-messages section.new-message form .uploaded-files-container .uploaded-files .video-new-message-container {
  display: flex;
  align-items: start;
  margin: 5px 25px 5px 0;
}
section.admin-messages section.new-message form .uploaded-files-container .uploaded-files .video-new-message-container video {
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
}
section.admin-messages section.new-message form input[type=submit]#valid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-messages section.new-message form input[type=submit]#valid:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
section.admin-messages section.new-message form input[type=submit]#invalid {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: black;
  color: linen;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
  transition: 0.2s ease;
}
section.admin-messages section.message-management form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid linen;
  border-radius: 20px;
}
section.admin-messages section.message-management form .name-select-message {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.admin-messages section.message-management form .name-select-message input {
  margin-left: 30px;
  width: 70%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-messages section.message-management form .name-select-message input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-messages section.message-management form .name-select-message input:hover {
  background: #b1852d;
}
section.admin-messages section.message-management form .name-select-message input:focus {
  background: #b1852d;
}
section.admin-messages section.message-management form .others-select-options {
  margin-top: 20px;
  height: 120px;
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
}
section.admin-messages section.message-management form .others-select-options select {
  flex: 1;
  margin: 20px 0;
  padding: 5px;
  font-size: 1rem;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
  color: black;
}
section.admin-messages section.message-management form .others-select-options select:hover {
  border: 2px solid rgb(186, 186, 186);
}
section.admin-messages section.message-management form .others-select-options .date-publication {
  margin-top: 20px;
}
section.admin-messages section.message-management form .others-select-options .date-publication input {
  height: 100%;
  margin: 5px 20px 5px 10px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-messages section.message-management form .others-select-options .date-publication input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-messages section.message-management form .others-select-options .date-publication input:hover {
  background: #b1852d;
}
section.admin-messages section.message-management form .others-select-options .date-publication input:focus {
  background: #b1852d;
}
section.admin-messages section.message-management button.btn-refresh {
  display: flex;
  align-items: center;
  position: absolute;
  right: 7%;
  transform: translate(0, -110%);
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-messages section.message-management button.btn-refresh:hover {
  background: #b1852d;
}
section.admin-messages section.message-management button.btn-refresh i {
  font-size: 1.5rem;
}
section.admin-messages section.message-management p {
  margin: 20px 0;
  text-align: center;
  font-size: 1.5rem;
}
section.admin-messages section.message-management table.message-table {
  width: 95%;
  margin: 20px auto;
  color: #e2b259;
}
section.admin-messages section.message-management table.message-table, section.admin-messages section.message-management table.message-table th, section.admin-messages section.message-management table.message-table td {
  border-collapse: collapse;
  border: 2px solid linen;
  padding: 5px;
}
section.admin-messages section.message-management table.message-table .first-category, section.admin-messages section.message-management table.message-table th .first-category, section.admin-messages section.message-management table.message-table td .first-category {
  color: red;
}
section.admin-messages section.message-management table.message-table.contain-btn, section.admin-messages section.message-management table.message-table th.contain-btn, section.admin-messages section.message-management table.message-table td.contain-btn {
  text-align: center;
}
section.admin-messages section.message-management table.message-table.contain-btn button:disabled:hover, section.admin-messages section.message-management table.message-table th.contain-btn button:disabled:hover, section.admin-messages section.message-management table.message-table td.contain-btn button:disabled:hover {
  color: red;
}
section.admin-messages section.message-management table.message-table button {
  width: 100%;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
section.admin-messages section.message-management table.message-table button:hover {
  background-color: #b1852d;
  color: linen;
}
section.admin-messages section.message-management table.message-table tr.deleted {
  background-color: rgb(175, 33, 33);
}
section.admin-messages section.message-management div {
  text-align: center;
}
section.admin-messages section.message-management div button {
  padding: 5px;
  margin: 5px;
  background-color: #e2b259;
  border: 1px solid linen;
  border-radius: 10px;
  cursor: pointer;
}
section.admin-messages section.message-management div button:hover {
  background-color: #b1852d;
  color: linen;
}
section.admin-messages section.message-management section.message-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
section.admin-messages section.message-management section.message-overview .message {
  display: flex;
  align-items: center;
  margin: 15px;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
section.admin-messages section.message-management section.message-overview .message img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-right: 5px;
}

section.admin-content .selector-container {
  width: 50%;
  margin: 0 auto;
}
section.admin-content form.form-admin-content h3 {
  font-size: 1.5rem;
  margin: 20px auto;
  padding: 10px;
  width: fit-content;
  color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
section.admin-content form.form-admin-content .link-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin: 20px auto;
  width: 90%;
}
section.admin-content form.form-admin-content .link-container label {
  font-size: 1.2rem;
  color: linen;
  margin: 0 10px;
}
section.admin-content form.form-admin-content .link-container input {
  grid-column: 2/4;
  width: 90%;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px;
  background-color: #e2b259;
  border: 2px solid linen;
  color: black;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper h3 {
  margin: 80px auto 20px auto;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .label-btn {
  display: flex;
  align-items: center;
  margin: 20px auto;
  padding: 7px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .label-btn:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .newspaper-photo-delete {
  display: flex;
  flex-direction: column;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .newspaper-photo-delete img {
  width: 200px;
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 10px 0;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .newspaper-photo-delete button {
  width: 75%;
  margin: 10px auto;
  background-color: rgb(175, 33, 33);
  border: 2px solid rgb(175, 33, 33);
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .newspaper-photo-delete button:hover {
  background: red;
  transform: scale(1.1);
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .news-city-linkNewspaper-add {
  display: flex;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .news-city-linkNewspaper-add button {
  font-size: 25px;
  width: 50%;
  margin: 10px auto;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-content form.form-admin-content section.news-city-linkNewspaper .news-city-linkNewspaper-container .news-city-linkNewspaper-add button:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.admin-content form.form-admin-content .label-guided-tour-magazine {
  display: flex;
}
section.admin-content form.form-admin-content .label-guided-tour-magazine .label-btn {
  display: block;
  width: fit-content;
  margin: 20px auto;
  padding: 10px;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-content form.form-admin-content .label-guided-tour-magazine .label-btn:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
section.admin-content form.form-admin-content input[type=submit] {
  display: block;
  width: 50%;
  margin: 20px auto;
  background-color: #e2b259;
  color: black;
  font-size: large;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-content form.form-admin-content input[type=submit]:hover {
  background-color: #b1852d;
  transform: scale(1.1);
}
section.admin-content section.guided-tour-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
section.admin-content section.guided-tour-overview .guided-tour {
  display: flex;
  align-items: center;
  margin: 15px;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
}
section.admin-content section.guided-tour-overview .guided-tour img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-right: 5px;
}
section.admin-content section.magazine-overview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
section.admin-content section.magazine-overview .magazine {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  width: 80%;
  height: 150px;
  background-size: cover;
  background-position: center 60%;
  color: linen;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
section.admin-content section.magazine-overview .magazine::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
section.admin-content section.magazine-overview .magazine .name-mag {
  display: flex;
  flex-direction: column;
}
section.admin-content section.magazine-overview .magazine .name-mag p {
  position: relative;
  z-index: 1;
}

section.admin-news .selected {
  margin-left: 20%;
}
section.admin-news h3 {
  margin-top: 20px;
  text-align: center;
}
section.admin-news .admin-content {
  display: flex;
  flex: 1;
  padding: 20px;
}
section.admin-news form.form-admin-news .new-news-setup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news {
  height: 100%;
  display: flex;
  justify-content: space-around;
  width: auto;
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group label {
  display: flex;
  align-items: flex-end;
  width: 80%;
  min-height: 30px;
  height: 15%;
  margin: 5px auto 0;
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group p {
  text-align: center;
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group input, section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group textarea {
  width: 80%;
  min-height: 30px;
  margin: 5px auto;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group input::placeholder, section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group textarea::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group input:hover, section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group textarea:hover {
  background: #b1852d;
}
section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group input:focus, section.admin-news form.form-admin-news .new-news-setup .info-new-news .input-group textarea:focus {
  background: #b1852d;
}
section.admin-news form.form-admin-news .description-new-news {
  width: 90%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
section.admin-news form.form-admin-news .description-new-news label {
  margin-bottom: 5px;
  cursor: default;
  background: none;
  border: none;
  color: linen;
}
section.admin-news form.form-admin-news .description-new-news label:hover {
  transform: none;
}
section.admin-news form.form-admin-news .description-new-news textarea {
  flex: 1;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-news form.form-admin-news .description-new-news textarea::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-news form.form-admin-news .description-new-news textarea:hover {
  background: #b1852d;
}
section.admin-news form.form-admin-news .description-new-news textarea:focus {
  background: #b1852d;
}
section.admin-news form.form-admin-news .new-news-content h3 {
  text-align: center;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container label {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 50px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container label:hover {
  background: #b1852d;
  transform: scale(1.1);
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container .links-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container .links-section .link-input {
  display: flex;
  flex-direction: column;
  margin: 2px;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container .links-section .link-input label {
  background: none;
  color: linen;
  border: none;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 15%;
  margin: 20px auto 0;
  pointer-events: none;
  transition: none;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container .links-section .link-input input {
  width: 150%;
  height: 30%;
  margin: 5px auto;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container .links-section .link-input input::placeholder {
  color: rgb(57, 57, 57);
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container .links-section .link-input input:hover {
  background: #b1852d;
}
section.admin-news form.form-admin-news .new-news-content .new-news-content-container .links-section .link-input input:focus {
  background: #b1852d;
}
section.admin-news form.form-admin-news .uploaded-logo-photo {
  margin-left: 35px;
  display: flex;
  gap: 30px;
}
section.admin-news form.form-admin-news .uploaded-logo-photo .uploaded-logo-photo-contain {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 70%;
  margin-left: 15%;
}
section.admin-news form.form-admin-news .uploaded-logo-photo .uploaded-logo-photo-contain .link-preview-img-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}
section.admin-news form.form-admin-news .uploaded-logo-photo img {
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 5px 0;
}
section.admin-news form.form-admin-news .uploaded-logo-photo .link-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  margin-right: 20px;
}
section.admin-news form.form-admin-news .uploaded-logo-photo .link-preview img {
  width: 100px;
  border-radius: 10px;
  border: none;
  margin: 5px 0;
}
section.admin-news form.form-admin-news .uploaded-logo-photo .link-preview .link-preview-text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
section.admin-news form.form-admin-news .uploaded-logo-photo .link-preview .link-preview-text h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #e2b259;
}
section.admin-news form.form-admin-news .uploaded-logo-photo .link-preview .link-preview-text p {
  margin: 5px 0 0;
  color: #e2b259;
}
section.admin-news form.form-admin-news section.preview-video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #e2b259;
  border-radius: 10px;
  background-color: linen;
  width: 80%;
}
section.admin-news form.form-admin-news section.preview-video iframe {
  width: 100%;
  max-width: 600px;
  height: 340px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

section.city-content .nav-guides ul {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  section.city-content .nav-guides ul {
    grid-template-columns: 1fr 1fr;
  }
}
section.city-content .nav-guides ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  section.city-content .nav-guides ul li {
    margin: 10px 20px;
  }
}
section.city-content .nav-guides ul li a, section.city-content .nav-guides ul li button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  width: 100%;
  color: #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  section.city-content .nav-guides ul li a, section.city-content .nav-guides ul li button {
    font-size: 20px;
  }
}
section.city-content .nav-guides ul li a:hover, section.city-content .nav-guides ul li button:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
section.city-content .nav-guides ul li a i, section.city-content .nav-guides ul li button i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  section.city-content .nav-guides ul li a i, section.city-content .nav-guides ul li button i {
    font-size: 3rem;
  }
}
section.city-content .nav-guides ul li a img.logo-pages-jaunes, section.city-content .nav-guides ul li button img.logo-pages-jaunes {
  width: 5rem;
}
@media (max-width: 768px) {
  section.city-content .nav-guides ul li a img.logo-pages-jaunes, section.city-content .nav-guides ul li button img.logo-pages-jaunes {
    width: 3rem;
  }
}
section.city-content .nav-guides ul li a img.logo-actualites, section.city-content .nav-guides ul li button img.logo-actualites {
  width: 250px;
  border-radius: 30px;
}
@media (max-width: 768px) {
  section.city-content .nav-guides ul li a img.logo-actualites, section.city-content .nav-guides ul li button img.logo-actualites {
    width: 125px;
  }
}
section.city-content .nav-guides ul li .newspaper button {
  width: auto;
  padding: 0;
}
section.city-content .nav-guides ul li .newspaper button img {
  width: 250px;
  border-radius: 30px;
}
@media (max-width: 768px) {
  section.city-content .nav-guides ul li .newspaper button img {
    width: 140px;
  }
}

section.privacy-policy {
  width: 90%;
  margin: 10px auto;
  color: linen;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 20px;
  padding: 20px;
  border: 2px solid #e2b259;
}
section.privacy-policy h1 {
  text-align: center;
  font-size: 3rem;
}
@media (max-width: 1024px) {
  section.privacy-policy h1 {
    font-size: 2.2rem;
  }
}
@media (max-width: 768px) {
  section.privacy-policy h1 {
    font-size: 1.5rem;
  }
}
section.privacy-policy h2 {
  font-size: 2rem;
}
@media (max-width: 1024px) {
  section.privacy-policy h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  section.privacy-policy h2 {
    font-size: 1.1rem;
  }
}
section.privacy-policy p {
  font-size: 1.2rem;
}
@media (max-width: 1024px) {
  section.privacy-policy p {
    font-size: 1.1rem;
  }
}
@media (max-width: 768px) {
  section.privacy-policy p {
    font-size: 1rem;
  }
}

section.categories-list {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}
@media (max-width: 1024px) {
  section.categories-list {
    width: 80%;
  }
}
@media (max-width: 768px) {
  section.categories-list {
    width: 90%;
  }
}
section.categories-list .category-searchbar-container {
  width: 70%;
  margin: 20px auto;
  padding: 10px;
  border: 2px solid linen;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e2b259;
}
@media (max-width: 1024px) {
  section.categories-list .category-searchbar-container {
    width: 80%;
  }
}
@media (max-width: 768px) {
  section.categories-list .category-searchbar-container {
    width: 90%;
  }
}
section.categories-list .category-searchbar-container input {
  width: 90%;
  height: 30px;
  padding: 5px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.categories-list .category-searchbar-container input::placeholder {
  color: rgb(57, 57, 57);
}
section.categories-list .category-searchbar-container input:hover {
  background: #b1852d;
}
section.categories-list .category-searchbar-container input:focus {
  background: #b1852d;
}

section.guided-tour-list {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  section.guided-tour-list {
    width: 95%;
  }
}
section.guided-tour-list .guided-tour-list-entry a {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
}
section.guided-tour-list .guided-tour-list-entry a:hover .distance {
  bottom: 10px;
  right: 20px;
}
section.guided-tour-list .guided-tour-list-entry a h2 {
  color: linen;
  margin-left: 10%;
}
@media (max-width: 1024px) {
  section.guided-tour-list .guided-tour-list-entry a h2 {
    font-size: 1rem;
  }
}
section.guided-tour-list .guided-tour-list-entry a img {
  width: 200px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  section.guided-tour-list .guided-tour-list-entry a img {
    width: 140px;
    height: 60px;
  }
}
section.guided-tour-list .guided-tour-list-entry a .distance {
  position: absolute;
  align-self: end;
  justify-self: end;
  right: 15px;
}
section.guided-tour-list .guided-tour-list-entry a .distance p {
  font-style: italic;
  color: linen;
  font-size: 1rem;
}
@media (max-width: 1024px) {
  section.guided-tour-list .guided-tour-list-entry a .distance p {
    font-size: 0.8rem;
  }
}

section.guided-tour {
  width: 90%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  color: linen;
  text-align: center;
}
section.guided-tour .infos-guided-tour img {
  width: 500px;
  height: 300px;
  border-radius: 10px;
  border: 2px solid #e2b259;
}
@media (max-width: 768px) {
  section.guided-tour .infos-guided-tour img {
    width: 300px;
    height: 200px;
  }
}
section.guided-tour .infos-guided-tour h2 {
  font-size: 3rem;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  section.guided-tour .infos-guided-tour h2 {
    font-size: 1.5rem;
  }
}
section.guided-tour .infos-guided-tour a {
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 10px;
  color: black;
  transition: 0.2s ease;
}
section.guided-tour .infos-guided-tour a:hover {
  transform: scale(1.1);
}
section.guided-tour .audio-guided-tour {
  margin-top: 40px;
}
section.guided-tour .audio-guided-tour h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
section.guided-tour .audio-guided-tour p {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
section.guided-tour .audio-guided-tour .languages-list {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
section.guided-tour .audio-guided-tour .languages-list img {
  width: 70px;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #e2b259;
  margin: 10px 5px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.guided-tour .audio-guided-tour .languages-list img:hover {
  transform: scale(1.1);
}
section.guided-tour .audio-guided-tour .languages-list img.selected {
  width: 100px;
  height: 70px;
  border: 2px solid #50b33a;
}
section.guided-tour .audio-guided-tour .audio-selected-language {
  margin-bottom: 20px;
}
section.guided-tour .audio-guided-tour .audio-selected-language button {
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 10px;
  color: black;
  transition: 0.2s ease;
  width: 150px;
}
section.guided-tour .audio-guided-tour .audio-selected-language button:hover {
  transform: scale(1.1);
}

section.magazine-redirect ul {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
}
@media (max-width: 768px) {
  section.magazine-redirect ul {
    grid-template-columns: 1fr 1fr;
  }
}
section.magazine-redirect ul li {
  margin: 20px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  section.magazine-redirect ul li {
    margin: 10px 20px;
  }
}
section.magazine-redirect ul li a {
  padding: 10px;
  font-size: 30px;
  font-family: "PoppinsMedium", sans-serif;
  height: 100%;
  color: #e2b259;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  border: 2px solid #e2b259;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  section.magazine-redirect ul li a {
    font-size: 20px;
  }
}
section.magazine-redirect ul li a:hover {
  color: #b1852d;
  border-color: #b1852d;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
section.magazine-redirect ul li a i {
  color: linen;
  font-size: 5rem;
}
@media (max-width: 768px) {
  section.magazine-redirect ul li a i {
    font-size: 3rem;
  }
}

section.magazines {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
section.magazines .magazine {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0;
  padding: 10px;
  background-color: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  width: 80%;
  height: 150px;
  background-size: cover;
  background-position: center 60%;
  color: linen;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
@media (max-width: 768px) {
  section.magazines .magazine {
    width: 90%;
    height: 100px;
    font-size: 1.2rem;
  }
}
section.magazines .magazine::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
section.magazines .magazine .name-mag {
  display: flex;
  flex-direction: column;
}
section.magazines .magazine .name-mag p {
  position: relative;
  z-index: 1;
}
@media (max-width: 600px) {
  section.magazines .magazine .name-mag p {
    font-size: 1.1rem;
  }
}
@media (max-width: 390px) {
  section.magazines .magazine .name-mag p {
    font-size: 0.9rem;
  }
}

section.message-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 95%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  section.message-list {
    grid-template-columns: 1fr;
  }
}
section.message-list .message-entry a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #e2b259;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: 0.2s ease;
  z-index: 1;
}
section.message-list .message-entry a .publication-date {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: linen;
  font-size: 0.8rem;
}
section.message-list .message-entry a .read-more {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  color: linen;
  font-size: 0.9rem;
  text-decoration: none;
}
section.message-list .message-entry a .read-more .arrow {
  margin-left: 5px;
  border: solid linen;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}
section.message-list .message-entry a h3 {
  color: linen;
  margin-left: 10%;
}
@media (max-width: 1024px) {
  section.message-list .message-entry a h3 {
    font-size: 1rem;
  }
}
section.message-list .message-entry a .message-content {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  margin-bottom: 20px;
}
section.message-list .message-entry a .message-content img {
  width: 100%;
  height: auto;
}
section.message-list .message-entry a .message-content p {
  margin-left: 20px;
  color: linen;
}
@media (max-width: 400px) {
  section.message-list .message-entry a .message-content p {
    font-size: 0.8rem;
  }
}
section.message-list .message-entry a:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.03);
}
section.message-list .message-entry a h2 {
  color: linen;
  margin-left: 10%;
}
@media (max-width: 1024px) {
  section.message-list .message-entry a h2 {
    font-size: 1rem;
  }
}
section.message-list .message-entry a img {
  width: 200px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  section.message-list .message-entry a img {
    width: 140px;
    height: 60px;
  }
}

section.message {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  color: linen;
  text-align: center;
}
section.message .infos-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.message .infos-message h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  section.message .infos-message h3 {
    font-size: 1rem;
  }
}
section.message .infos-message img {
  width: 300px;
  height: auto;
  border-radius: 10px;
  border: 2px solid #e2b259;
  transition: transform 0.3s ease;
}
section.message .infos-message img.enlarged {
  transform: scale(1.5);
  z-index: 1000;
}
@media (max-width: 768px) {
  section.message .infos-message img {
    width: 200px;
    height: auto;
  }
}
section.message .content-message {
  margin-top: 20px;
}
section.message .content-message p {
  margin: 0 20px 0 20px;
  text-align: justify;
}
@media (max-width: 768px) {
  section.message .content-message p {
    font-size: 0.8rem;
  }
}
section.message .content-message h4 {
  margin-top: 10px;
}
@media (max-width: 768px) {
  section.message .content-message h4 {
    font-size: 1rem;
  }
}
section.message .content-message ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
section.message .content-message ul li {
  margin: 0 10px;
}
section.message .content-message ul li button {
  background-color: #e2b259;
  color: black;
  border: 2px solid linen;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
section.message .content-message ul li button:hover {
  background-color: #e2b259;
  transform: scale(1.1);
}
section.message .message-uploads {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
section.message .message-uploads img {
  margin-bottom: 20px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  border: 2px solid #e2b259;
  cursor: pointer;
  transition: transform 0.3s ease;
}
section.message .message-uploads img.enlarged {
  transform: scale(1.7);
  z-index: 1000;
}
@media (max-width: 768px) {
  section.message .message-uploads img {
    width: 200px;
    height: auto;
  }
}

section.interview-list {
  width: 70%;
  margin: 0 auto;
}
section.interview-list a {
  display: flex;
  margin: 20px 0;
  padding: 30px;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  transition: 0.2s ease;
}
@media (max-width: 768px) {
  section.interview-list a {
    padding: 10px;
    margin: 10px 10% 0 10%;
    flex-direction: column;
  }
}
section.interview-list a img {
  padding: 5px;
  min-width: 200px;
  max-width: 200px;
  height: 75px;
  object-fit: contain;
  background-color: linen;
  border-radius: 10px;
}
@media (max-width: 768px) {
  section.interview-list a img {
    min-width: 150px;
    max-width: 150px;
    height: auto;
  }
}

section.interview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 10px;
  color: linen;
  text-align: center;
}
section.interview .interview-information {
  display: flex;
}
@media (max-width: 1024px) {
  section.interview .interview-information {
    flex-direction: column;
    align-items: center;
  }
}
section.interview .interview-information .image-container {
  margin: 5%;
  width: 40%;
  height: auto;
  transition: transform 0.3s ease;
}
@media (max-width: 1024px) {
  section.interview .interview-information .image-container {
    width: 60%;
    margin: 0 auto;
  }
}
section.interview .interview-information .image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 768px) {
  section.interview .interview-information .image-container {
    width: 200px;
    height: auto;
  }
}
@media (max-width: 1024px) {
  section.interview .interview-information .image-container {
    width: 60%;
    margin: 0 auto;
  }
}
section.interview .interview-information .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  width: 60%;
  overflow: hidden;
}
@media (max-width: 1024px) {
  section.interview .interview-information .text-container {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 1024px) {
  section.interview .interview-information .text-container .text-information-container {
    width: 100%;
    text-align: center;
  }
}
section.interview .interview-information .text-container .text-information-container .text-name-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
@media (max-width: 1024px) {
  section.interview .interview-information .text-container .text-information-container .text-name-container {
    font-size: 1.5rem;
  }
}
@media (max-width: 480px) {
  section.interview .interview-information .text-container .text-information-container .text-name-container {
    font-size: 1rem;
  }
}
section.interview .interview-information .text-container .text-information-container .text-job-container {
  font-size: 30px;
}
@media (max-width: 1024px) {
  section.interview .interview-information .text-container .text-information-container .text-job-container {
    margin-top: 30px;
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  section.interview .interview-information .text-container .text-information-container .text-job-container {
    font-size: 0.8rem;
  }
}
section.interview .interview-information .text-container .text-description-container {
  font-size: 20px;
  margin-top: 5%;
  max-width: 80%;
}
@media (max-width: 1024px) {
  section.interview .interview-information .text-container .text-description-container {
    max-width: 100%;
  }
}
section.interview .interview-information .text-container .text-description-container .text-description {
  text-align: justify;
  position: relative;
  margin-top: 20px;
  border: 2px solid white;
  border-radius: 20px;
  padding: 10px 10px 40px 10px;
  cursor: pointer;
  white-space: pre-wrap;
}
section.interview .interview-information .text-container .text-description-container .text-description .read-more {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  color: linen;
  font-size: 0.9rem;
  text-decoration: none;
}
section.interview .interview-information .text-container .text-description-container .text-description .read-more .arrow {
  margin-left: 5px;
  border: solid linen;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}
section.interview .partner-information {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid linen;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  color: linen;
}
section.interview .partner-information .partner-information-title {
  display: flex;
  align-items: center;
  gap: 90%;
}
@media (max-width: 1024px) {
  section.interview .partner-information .partner-information-title {
    gap: 10%;
  }
}
section.interview .partner-information .partner-information-title img {
  width: 100px;
  height: auto;
  border-radius: 10px;
}
section.interview .partner-information .partner-information-title .btn-interview {
  display: block;
  width: 100%;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #e2b259;
  color: black;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}
section.interview .partner-information .partner-information-title .btn-interview:hover {
  background-color: red;
}
section.interview .partner-information .partner-information-content a {
  color: #e2b259;
  text-decoration: underline;
  font-style: italic;
}
section.interview .partner-information .partner-information-content a:hover {
  color: #b1852d;
}
@media (max-width: 1024px) {
  section.interview .partner-information {
    flex-direction: column;
  }
  section.interview .partner-information .partner-information-content {
    margin-top: 10px;
  }
}
section.interview .contact-currentPartner-container {
  background-color: rgba(0, 0, 0, 0.75);
  padding-top: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
section.interview .contact-currentPartner-container .info-contact-currentPartner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
section.interview .contact-currentPartner-container .info-contact-currentPartner-container a {
  flex: 0 1 calc(16.66% - 20px);
  margin: 10px;
  box-sizing: border-box;
  color: #e2b259;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}
section.interview .contact-currentPartner-container .info-contact-currentPartner-container a:hover {
  color: #b1852d;
}
@media (max-width: 768px) {
  section.interview .contact-currentPartner-container .info-contact-currentPartner-container a {
    font-size: 1.9rem;
    flex: 0 1 calc(33.33% - 20px);
  }
}
section.interview .contact-currentPartner-container .info-contact-currentPartner-container a img {
  border: none;
  background: none;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  section.interview .contact-currentPartner-container .info-contact-currentPartner-container a img {
    max-width: 25px;
    min-width: 25px;
    min-height: 25px;
    max-height: 25px;
  }
}

section.album-list .status-user {
  padding: 10px;
  border-radius: 20px;
  margin: 10px 20% 100px 20%;
  border: 2px solid #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  section.album-list .status-user {
    margin: 10px 5% 50px 5%;
  }
}
section.album-list .status-user .check-box-condition:first-child {
  margin-bottom: 20px;
}
section.album-list .album-list-select {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 20%;
  padding: 20px 10px;
  border-radius: 20px;
  border: 2px solid #e2b259;
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 768px) {
  section.album-list .album-list-select {
    margin: 15px 5%;
    font-size: 0.8rem;
    padding: 10px 5px;
  }
}

section.album img {
  display: flex;
  justify-content: center;
  border: 3px solid #e2b259;
  border-radius: 20px;
  width: 20%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  section.album img {
    width: 40%;
  }
}
section.album .album-content-text {
  padding: 10px;
  border-radius: 20px;
  margin: 10px 20% 50px 20%;
  border: 2px solid #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
}
@media (max-width: 768px) {
  section.album .album-content-text {
    margin: 10px 5% 20px 5%;
    font-size: 0.8rem;
  }
}
section.album .album-content-text p {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
section.album section.album-content-audio h2 {
  margin: 20px 0;
  color: white;
  text-align: center;
}
@media (max-width: 768px) {
  section.album section.album-content-audio h2 {
    font-size: 1.2rem;
  }
}
section.album section.album-content-audio .list-audio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px 20%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 20px;
  padding: 10px;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio {
    margin: 20px 5%;
    gap: 15px;
  }
}
section.album section.album-content-audio .list-audio .audio-content {
  width: 90%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to bottom right, #e2b259, rgba(0, 0, 0, 0.75));
  border-radius: 10px;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content {
    flex-direction: column;
    padding: 10px;
    text-align: center;
  }
}
section.album section.album-content-audio .list-audio .audio-content p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  text-transform: uppercase;
  margin: 0 10px;
  flex: 2;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
section.album section.album-content-audio .list-audio .audio-content p span {
  display: none;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content p span {
    display: inline;
  }
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content p {
    font-size: 1rem;
    margin: 5px 0;
    gap: 10px;
  }
}
section.album section.album-content-audio .list-audio .audio-content .audio-content-data {
  flex: 0.5;
  text-align: right;
  margin: 0 10px;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content .audio-content-data {
    text-align: center;
    margin: 5px 0;
  }
}
section.album section.album-content-audio .list-audio .audio-content .timepreview {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content .timepreview {
    flex-direction: row;
    gap: 10px;
  }
}
section.album section.album-content-audio .list-audio .audio-content .timepreview .text-demo {
  text-transform: none;
  font-size: 1rem;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content .timepreview .text-demo {
    gap: 5px;
  }
}
section.album section.album-content-audio .list-audio .audio-content .button-album {
  display: flex;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content .button-album {
    flex-direction: row;
    gap: 20px;
  }
}
section.album section.album-content-audio .list-audio .audio-content .button-album button {
  background-color: #e2b259;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content .button-album button {
    width: 50px;
    height: 50px;
    margin: 5px 0;
  }
}
section.album section.album-content-audio .list-audio .audio-content .button-album button:hover {
  background-color: rgb(218.4153846154, 157.8615384615, 45.5846153846);
  transform: scale(1.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
section.album section.album-content-audio .list-audio .audio-content .button-album button svg {
  color: white;
  fill: white;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content .button-album button svg {
    width: 20px;
    height: 20px;
  }
}
section.album section.album-content-audio .list-audio .audio-content .button-album button[data-playing=true] svg {
  transform: scale(1.1);
}
section.album section.album-content-audio .list-audio .audio-content .button-album .bxs-cart-add {
  font-size: 2rem;
}
@media (max-width: 768px) {
  section.album section.album-content-audio .list-audio .audio-content .button-album .bxs-cart-add {
    font-size: 1.5rem;
  }
}

.album-download {
  padding: 20px;
}
.album-download button.button-download {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  margin: 20px auto;
  border: 2px solid #e2b259;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 768px) {
  .album-download button.button-download {
    margin: 10px auto;
    font-size: 1rem;
    padding: 8px;
  }
}

section.cart {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #e2b259;
  border-radius: 20px;
  margin: 20px 20%;
  color: white;
}
@media (max-width: 768px) {
  section.cart {
    margin: 20px 5%;
  }
}
section.cart .cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e2b259;
  font-size: 1.2rem;
}
@media (max-width: 768px) {
  section.cart .cart-item {
    font-size: 1rem;
  }
}
section.cart .cart-item .cart-item-title {
  flex: 2;
  margin: 0;
}
section.cart .cart-item .cart-item-price {
  align-content: flex-end;
  flex: 1;
  text-align: center;
  margin: 0;
}
section.cart .cart-item button {
  background-color: #e2b259;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  section.cart .cart-item button {
    font-size: 1.2rem;
  }
}
section.cart .cart-item button:hover {
  background-color: rgb(218.4153846154, 157.8615384615, 45.5846153846);
}
section.cart .cart-summary {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #e2b259;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.cart .cart-summary p {
  margin: 0;
  font-size: 1.2rem;
}
@media (max-width: 768px) {
  section.cart .cart-summary p {
    font-size: 1rem;
  }
}
section.cart .cart-summary .cart-button {
  display: flex;
  gap: 20px;
}
section.cart .cart-summary .cart-button button {
  background-color: #e2b259;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}
@media (max-width: 768px) {
  section.cart .cart-summary .cart-button button {
    font-size: 0.8rem;
    padding: 5px 10px;
    border-radius: 10px;
  }
}
section.cart .cart-summary .cart-button button:hover {
  background-color: rgb(218.4153846154, 157.8615384615, 45.5846153846);
}

section.statistique .button-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
section.statistique .btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2b259;
  font-size: 1rem;
  padding: 10px;
  border: 2px solid linen;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s ease;
}

section.contact {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 90%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  padding: 20px;
  margin-top: 20px;
  border: 2px solid #e2b259;
  text-align: center;
}
section.contact .QRCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
section.contact .QRCode img {
  max-width: 300px;
  border: 2px solid #e2b259;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  section.contact .QRCode img {
    max-width: 250px;
  }
}
section.contact a {
  display: block;
  color: linen;
  font-size: 1.2rem;
}
section.contact a:hover {
  color: #e2b259;
}
section.contact a i {
  font-size: 2rem;
  transform: translate(0, 5px);
}

section.account-user {
  margin-bottom: 60px;
}
section.account-user .info-account-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15%;
  margin: 30px 0;
}
section.account-user .info-account-user .info-personnels-account-user {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
section.account-user .info-account-user .info-personnels-account-user .info-field {
  margin-bottom: 15px;
}
section.account-user .info-account-user .info-personnels-account-user .info-field input {
  width: 100%;
  min-height: 40px;
  margin: 10px 0;
  padding: 10px;
  background: #e2b259;
  border: 1px solid linen;
  border-radius: 5px;
  transition: 0.2s ease;
}
section.account-user .info-account-user .info-personnels-account-user .info-field input::placeholder {
  color: black;
}
section.account-user .info-account-user .info-personnels-account-user .info-field input:hover {
  background: #e2b259;
}
section.account-user .info-account-user .info-personnels-account-user .info-field input:focus {
  background: #e2b259;
  outline: none;
}
section.account-user .info-account-user .info-personnels-account-user .info-field label {
  color: #e2b259;
  font-weight: bold;
}
section.account-user .info-account-user .info-personnels-account-user .info-field p {
  margin: 0;
}
section.account-user .button-account-user {
  display: flex;
  margin: 20px 0;
  justify-content: center;
}
section.account-user .button-account-user .button-group {
  display: flex;
  gap: 40px;
}
section.account-user .button-account-user button {
  padding: 10px;
  background: #e2b259;
  border: 2px solid linen;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 0.2s ease;
}
section.account-user .button-account-user button:hover {
  background: #b1852d;
}
@media (max-width: 768px) {
  section.account-user .info-account-user {
    flex-direction: column;
    gap: 20px;
  }
  section.account-user .info-account-user .info-personnels-account-user {
    gap: 20px;
    width: 80%;
  }
  section.account-user .button-account-user .button-group {
    flex-direction: row;
    gap: 20px;
  }
}

