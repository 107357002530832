.backoffice-container {
  @include setup-admin;
  header{
    position: relative;
    z-index: 999;
  }

  .backoffice-content-container {
    width: 90%;
    margin: 10px auto;
    color: $white;
    background: $bgiconscolorH;
    border-radius: 20px;
    border: 2px solid $white;

    .backoffice-content {
      h2 {
        font-size: 2rem;
        margin: 20px auto;
        padding: 10px;
        width: fit-content;
        color: $fontcolor1;
        border: $borderWhite;
        border-radius: 10px;
      }


      input[type="submit"] {
        margin: 20px auto;
        background-color: $fontcolor1;
        color: $black;
        font-size: large;
        border: 2px solid $white;
        border-radius: 10px;
        padding: 10px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          background-color: $fontcolor1H;
          transform: scale(1.1);
        }
      }
    }
  }

  @include respond-to(small) {
    .backoffice-content-container {
      border-radius: 0;
      width: 100%;
      margin: 5px auto;
      border-left: none;
      border-right: none;

      .backoffice-content {
        h2 {
          font-size: 1.2rem;
          margin: 20px auto 5px auto;
          padding: 5px;
        }

        input[type="submit"] {
          font-size: medium;
          padding: 5px;
        }
      }
    }
  }

  .modal-bigger-picture {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
      max-width: 90%;
      max-height: 90%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}