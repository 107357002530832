form.form-admin-new-guided-tour {
  section.guided-tour-infos {
    .label-info-guided-tour-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      .label-input-guided-tour{
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          height: 30px;
          padding: 3px;
          background-color: $white;
          border: $borderBlack;
          border-radius: 10px;

          &:focus {
            outline: none;
          }
        }
      }

      .label-btn {
        display: flex;
        align-items: center;
        padding: 7px;
        height: 50px;
        background: $white;
        border: $borderBlack;
        border-radius: 10px;
        color: $black;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }



    img {
      display: block;
      max-width: 200px;
      max-height: 300px;
      margin: 20px auto;
      border: $borderWhite;
      border-radius: 10px;
    }
  }

  section.guided-tour-audio {
    text-align: center;

    .searchbar-country {
      margin-bottom: 20px;

      label {
        font-size: 1.3rem;
        margin-right: 20px;
      }

      input {
        width: 23%;
        height: 30px;
        padding: 5px;
        font-size: 1rem;
        background-color: $white;
        border: $borderBlack;
        border-radius: 10px;

        &:focus {
          outline: none;
        }
      }
    }

    .flags-container {
      border: $borderWhite;
      border-radius: 20px;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 50px;
        }
      }
    }

    .audio-each-language {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      div {
        text-align: right;

        i {
          font-size: 2rem;
          width: fit-content;
          text-align: end;
          color: $red;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            color: $red2;
            transform: scale(1.1);
          }
        }

        .center-audio-country {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            cursor: pointer;

            img {
              width: 100px;
            }
          }

          audio {
            min-width: 250px;
          }
        }
      }
    }
  }
}

