.image-gallery {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 50px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
  width: 80%;
  height: 90%;
  @include respond-to(medium) {
    width: 95%;
    height: 70%;
    padding: 10px;

  }

  .bottom-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button {
      background: rgba(255, 0, 0, 0.8);
      border: none;
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 10px;
      border-radius: 50%;
      transition: background 0.3s;
      margin: 0 10px;
    }

    button:hover {
      background: rgba(255, 0, 0, 0.8);
    }
  }
}

.image-gallery img {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
  margin: 10px 0;
}

.image-gallery .controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
}

.image-gallery button {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s;
}

.image-gallery button:hover {
  background: rgba(255, 255, 255, 0.5);
}

.image-gallery .close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 0, 0, 0.8);
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.image-gallery .close-button:hover {
  background: rgba(255, 0, 0, 1);
}

.image-gallery .prev-button,
.image-gallery .next-button {
  opacity: 0;
  pointer-events: auto;
  display: block;
  position: absolute;
  height: 40%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.image-gallery .prev-button {
  left: 10px;
}

.image-gallery .next-button {
  right: 10px;
}

.image-gallery .prev-button:hover,
.image-gallery .next-button:hover {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

@include respond-to(medium) {
  .image-gallery .prev-button:hover,
  .image-gallery .next-button:hover {
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
  }
}