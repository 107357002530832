.popup-content-partner {
  .popup-content-partner-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 30px;
    width: 40%;
    text-align: center;
    max-height: 95vh;
    overflow-y: scroll;
    @include respond-to(large) {
      width: 50%;
    }
    @include respond-to(medium) {
      width: 60%;
    }
    @include respond-to(small) {
      width: 80%;
    }
    .popup-content-partner-header {
      display: flex;
      justify-content: right;
      align-items: start;
      margin-bottom: 20px;
      margin-left: 32px;
      img {
        max-width: 80%;
        max-height: 200px;
        border-radius: 20px;
        margin: 0 auto;
      }
      i {
        font-size: 2rem;
        color: $red;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          color: $red2;
        }
      }
    }
    .popup-content-partner-text {
      h3 {
        font-size: 2rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
    .popup-content-partner-uploads {
      margin: 20px 0;
      .popup-content-uploads-container{
        padding: 5px;
        img {
          max-width: 100%;
          max-height: 150px;
          border-radius: 10px;
          margin-right: 20px;
        }
        video {
          max-width: 100%;
          max-height: 150px;
          border-radius: 10px;
          margin-right: 20px;
        }
      }
    }
  }
}