section.admin-content {
  .selector-container {
    width: 50%;
    margin: 0 auto;
  }

  form.form-admin-content {
    h3 {
      font-size: 1.5rem;
      margin: 20px auto;
      padding: 10px;
      width: fit-content;
      color: $fontcolor1;
      border: 2px solid $white;
      border-radius: 10px;
    }

    .link-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      margin: 20px auto;
      width: 90%;

      label {
        font-size: 1.2rem;
        color: $white;
        margin: 0 10px;
      }

      input {
        grid-column: 2/4;
        width: 90%;
        margin: 0 auto;
        padding: 5px;
        border-radius: 5px;
        background-color: $fontcolor1;
        border: $borderWhite;
        color: $black;
      }
    }

    section.news-city-linkNewspaper {
      h3 {
        margin: 80px auto 20px auto;
      }

      .news-city-linkNewspaper-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          border-radius: 5px;
        }

        .label-btn {
          display: flex;
          align-items: center;
          margin: 20px auto;
          padding: 7px;
          background: $fontcolor1;
          border: 2px solid $white;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            background: $fontcolor1H;
            transform: scale(1.1);
          }
        }

        .newspaper-photo-delete {
          display: flex;
          flex-direction: column;

          img {
            width: 200px;
            border-radius: 10px;
            border: 2px solid $fontcolor1;
            margin: 10px 0;
          }

          button {
            width: 75%;
            margin: 10px auto;
            background-color: $red;
            border: 2px solid $red;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              background: red;
              transform: scale(1.1);
            }
          }
        }

        .news-city-linkNewspaper-add {
          display: flex;

          button {
            font-size: 25px;
            width: 50%;
            margin: 10px auto;
            background: $fontcolor1;
            border: 2px solid $white;
            border-radius: 10px;
            color: $black;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
              background: $fontcolor1H;
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .label-guided-tour-magazine {
      display: flex;

      .label-btn {
        display: block;
        width: fit-content;
        margin: 20px auto;
        padding: 10px;
        background-color: $fontcolor1;
        color: $black;
        font-size: large;
        border: 2px solid $white;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          background-color: $fontcolor1H;
          transform: scale(1.1);
        }
      }
    }

    input[type="submit"] {
      display: block;
      width: 50%;
      margin: 20px auto;
      background-color: $fontcolor1;
      color: $black;
      font-size: large;
      border: 2px solid $white;
      border-radius: 10px;
      padding: 5px;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        background-color: $fontcolor1H;
        transform: scale(1.1);
      }
    }
  }

  section.guided-tour-overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .guided-tour {
      display: flex;
      align-items: center;
      margin: 15px;
      padding: 10px;
      background-color: $fontcolor1;
      border: $borderWhite;
      border-radius: 10px;

      img {
        width: 200px;
        height: 150px;
        object-fit: cover;
        margin-right: 5px;
      }
    }
  }

  section.magazine-overview {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .magazine {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 15px 0;
      padding: 10px;
      background-color: $fontcolor1;
      border: $borderWhite;
      border-radius: 10px;
      width: 80%;
      height: 150px;
      background-size: cover;
      background-position: center 60%;
      color: $white;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
      }

      .name-mag {
        display: flex;
        flex-direction: column;

        p {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}