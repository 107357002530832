form.form-admin-update-guided-tour {
  .update-guided-tour-setup {
    .info-update-guided-tour {
      text-align: center;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 15px;
      }

      .info-update-guided-tour-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        max-height: 70px;
        margin-bottom: 20px;

        input {
          width: 23%;
          height: 30px;
        }
      }

      .update-guided-tour-content {
        display: flex;

        img {
          display: block;
          max-width: 600px;
          max-height: 300px;
          margin: 20px auto;
          border: $borderWhite;
          border-radius: 10px;
        }

        .label-btn {
          align-items: center;
          padding: 7px;
          height: 50px;
          background: $white;
          border: $borderBlack;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      section.guided-tour-audio {
        text-align: center;

        .searchbar-country {
          margin-bottom: 20px;

          label {
            font-size: 1.3rem;
            margin-right: 20px;
          }

          input {
            width: 23%;
            height: 30px;
          }
        }

        .flags-container {
          border: $borderWhite;
          border-radius: 20px;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 50px;
            }
          }
        }

        .audio-each-language {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;

          div {
            text-align: right;

            i {
              font-size: 2rem;
              width: fit-content;
              text-align: end;
              color: $red;
              cursor: pointer;
              transition: 0.2s ease;

              &:hover {
                color: $red2;
                transform: scale(1.1);
              }
            }

            .center-audio-country {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 10px;

              label {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 1.5rem;
                cursor: pointer;

                img {
                  width: 100px;
                }
              }

              audio {
                min-width: 250px;
              }
            }
          }
        }
      }
    }
  }
}