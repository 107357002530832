.status-user {
  margin-bottom: 20px;

  .check-box-condition {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #989898;
      font-size: 0.8rem;
      padding: 0 10%;
      text-align: justify;
      margin-bottom: 20px;

      @include respond-to(small) {
        padding: 0 5%;
        font-size: 0.7rem;
      }
    }

    .checkbox-label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: $fontcolor1;
      cursor: pointer;

      input[type="checkbox"] {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        cursor: pointer;
      }

      &:hover {
        color: $fontcolor1H;
      }
    }
  }
}