section.paginated-list{
  section.paginated-list-entry{

  a{
    color: $fontcolor1;
    border: 2px solid $fontcolor1;
    border-radius: 20px;
    background-color: $bgiconscolor;

    &:hover {
      background-color: $bgiconscolorH;
      transform: scale(1.03);
    }
  }

  }

  .load-more-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    color: $fontcolor1;
    background-color: $bgiconscolor;
    border: 2px solid $fontcolor1;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: $bgiconscolorH;
      transform: scale(1.05);
    }

    @include respond-to(small) {
      width: 150px;
      font-size: 0.875rem;
    }
  }
}

