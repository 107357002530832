section.album {
  img {
    display: flex;
    justify-content: center;
    border: 3px solid $fontcolor1;
    border-radius: 20px;
    width: 20%;
    margin: 0 auto;
    @include respond-to(small) {
      width: 40%;
    }
  }

  .album-content-text {
    padding: 10px;
    border-radius: 20px;
    margin: 10px 20% 50px 20%;
    border: 2px solid $fontcolor1;
    background-color: $bgiconscolor;
    color: white;

    @include respond-to(small) {
      margin: 10px 5% 20px 5%;
      font-size: 0.8rem;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: justify;
    }
  }

  section.album-content-audio {
    h2 {
      margin: 20px 0;
      color: white;
      text-align: center;

      @include respond-to(small) {
        font-size: 1.2rem;
      }
    }

    .list-audio {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 30px;
      margin: 20px 20%;
      background-color: $bgiconscolor;
      border: 2px solid $fontcolor1;
      border-radius: 20px;
      padding: 10px;

      @include respond-to(small) {
        margin: 20px 5%; // Réduire la marge pour les petits écrans
        gap: 15px; // Réduire l'espace entre les éléments
      }

      .audio-content {
        width: 90%;
        min-height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background: linear-gradient(to bottom right, $fontcolor1, $bgiconscolor);
        border-radius: 10px;

        @include respond-to(small) {
          flex-direction: column;
          padding: 10px;
          text-align: center;
        }

        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 1.2rem;
          color: white;
          text-transform: uppercase;
          margin: 0 10px;
          flex: 2;
          white-space: normal;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;

          span{
            display: none;
            @include respond-to(small) {
              display: inline;

            }
          }

          @include respond-to(small) {
            font-size: 1rem; // Ajuster la taille de la police pour les petits écrans
            margin: 5px 0; // Réduire la marge
            gap: 10px;
          }
        }

        .audio-content-data {
          flex: 0.5;
          text-align: right;
          margin: 0 10px;

          @include respond-to(small) {
            text-align: center; // Centrer le prix
            margin: 5px 0; // Réduire la marge
          }
        }

        .timepreview {
          display: flex;
          flex-direction: column;
          @include respond-to(small) {
            flex-direction: row;
            gap: 10px;
          }
          .text-demo {
            text-transform: none;
            font-size: 1rem;
            flex: none;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            @include respond-to(small) {
              gap: 5px;
            }
          }
        }

        .button-album {
          display: flex;

          @include respond-to(small) {
            flex-direction: row;
            gap: 20px;
          }

          button {
            background-color: $fontcolor1;
            border: none;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            margin: 0 10px;

            @include respond-to(small) {
              width: 50px; // Réduire la taille des boutons
              height: 50px;
              margin: 5px 0; // Réduire la marge
            }

            &:hover {
              background-color: darken($fontcolor1, 10%);
              transform: scale(1.1);
              box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
            }

            svg {
              color: white;
              fill: white;
              width: 24px;
              height: 24px;
              transition: transform 0.3s ease;

              @include respond-to(small) {
                width: 20px; // Réduire la taille des icônes
                height: 20px;
              }
            }

            &[data-playing="true"] {
              svg {
                transform: scale(1.1);
              }
            }
          }

          .bxs-cart-add {
            font-size: 2rem;

            @include respond-to(small) {
              font-size: 1.5rem; // Réduire la taille de l'icône du panier
            }
          }
        }
      }
    }
  }
}

.album-download {
  padding: 20px;

  button.button-download {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    margin: 20px auto;
    border: 2px solid $fontcolor1;
    background-color: $bgiconscolor;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;

    @include respond-to(small) {
      margin: 10px auto;
      font-size: 1rem;
      padding: 8px;
    }
  }
}