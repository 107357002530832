section.magazines {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .magazine {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 15px 0;
    padding: 10px;
    background-color: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    width: 80%;
    height: 150px;
    background-size: cover;
    background-position: center 60%;
    color: $white;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    @include respond-to(small) {
      width: 90%;
      height: 100px;
      font-size: 1.2rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }

    .name-mag {
      display: flex;
      flex-direction: column;

      p {
        position: relative;
        z-index: 1;
      }
    }

    @media (max-width: 600px) {
      .name-mag p {
        font-size: 1.1rem;
      }
    }

    @media (max-width: 390px) {
      .name-mag p {
        font-size: 0.9rem;
      }
    }
  }
}
