form {
  .update-message-setup {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    h3 {
      text-align: center;
    }

    .info-update-message,
    .city-update-message,
    .links-section {
      flex: 1 1 30%;
      min-width: 300px;
    }

    input {
      width: 90%;
      height: 15%;
      margin: 5px auto;
    }

    .info-update-message {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      label {
        display: flex;
        align-items: flex-end;
        width: 80%;
        min-height: 30px;
        height: 15%;
        margin: 5px auto 0;
      }

      p {
        text-align: center;
      }


    }

    .links-section {
      margin-left: 20px;

      label {
        display: flex;
        align-items: flex-end;
        width: 90%;
        min-height: 30px;
        height: 15%;
        margin: 5px auto 0;
      }
    }
  }

  .description-update-message {
    width: 90%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;

    label {
      margin-bottom: 5px;
      cursor: default;
      background: none;
      border: none;
      color: $black;

      &:hover {
        transform: none;
      }
    }

    textarea {
      width: 100%;
      height: 100%;
    }
  }

  .uploaded-logo {
    margin-left: 35px;

    img {
      border-radius: 10px;
      border: 2px solid $fontcolor1;
      margin: 5px 0;
    }
  }

  .uploaded-files-container {
    width: 95%;
    margin: 0 auto;
    border: 2px solid $white;
    border-radius: 10px;

    legend {
      margin-left: 20px;
      color: $black;
      font-size: 1.3rem;
    }

    .uploaded-files {
      display: flex;
      flex-wrap: wrap;

      i {
        color: $red;
        font-size: 2rem;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          color: $red2;
          transform: scale(1.1);
        }
      }

      .picture-new-partner-container {
        display: flex;
        align-items: start;
        margin: 5px 25px 5px 0;

        img {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          margin: 5px 10px;
        }
      }

      .video-new-partner-container {
        display: flex;
        align-items: start;
        margin: 5px 25px 5px 0;

        video {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          cursor: pointer;
        }
      }
    }
  }
}

