@keyframes dropdownAppear {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-buttons {
  display: flex;
  justify-content: space-between;

  .back-button {
    position: fixed;
    left: 2.5%;
    top: 2.5%;
    width: 80%;
    @include respond-to(small) {
      border-right: 2px solid black;
      top: 0;
      left: 0;
    }

    button {
      background-color: $fontcolor1;
      font-size: 1rem;
      padding: 20px;
      border: 2px solid $white;
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        background-color: $fontcolor1H;
        transform: scale(1.1);
      }

      @include respond-to(small) {
        padding: 10px 0;
        border: none;
        border-radius: 0;
        width: 100%;
      }
    }
  }

  .account-button {
    position: fixed;
    right: 2.5%;
    top: 2.5%;
    width: 20%;
    display: flex;
    justify-content: end;
    @include respond-to(small) {
      border-bottom: 1px solid $fontcolor1;
      top: 0;
      right: 0;
    }

    button {
      background-color: $fontcolor1;
      padding: 20px;
      border: 2px solid $white;
      border-radius: 20px;
      cursor: pointer;
      font-size: 2rem;

      &:hover {
        background-color: $fontcolor1H;
        transform: scale(1.1);

        @include respond-to(small) {
          background-color: $fontcolor1;
          transform: scale(1);
        }
      }

      &.drop-show {
        border-radius: 20px 20px 0 0;
        background-color: $fontcolor1H;

        @include respond-to(small) {
            border-radius: 0;
        }

        &:hover {
          transform: scale(1);
        }
      }

      @include respond-to(small) {
        padding: 10px 0;
        border: none;
        border-radius: 0;
        width: 100%;
        font-size: 1.5rem;
      }
    }
  }

  .dropdown-content {
    position: fixed;
    width: 25%;
    top: 10%;
    right: 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid white;
    border-radius: 20px;
    background-color: $fontcolor1H;
    padding: 10px 0;
    gap: 10px;
    font-size: 1.2rem;
    animation: dropdownAppear 0.3s ease-out;

    @include respond-to(small) {
      top: 5%;
      right: 0;
      width: 100%;
      border-radius: 0;
      border: none;
      border-top: 2px solid black;

    }

    &.drop-show {
      border-top-right-radius: 0;
    }

    .dropdown-item {
      background-color: $fontcolor1;
      padding: 20px;
      border: 2px solid $white;
      border-radius: 20px;
      cursor: pointer;
      font-size: 1.2rem;
      text-align: center;
      display: block;
      width: 100%;
      color: black;
      text-decoration: none;

      &:hover {
        background-color: $fontcolor1H;
        transform: scale(1.2);
        color: red;
      }

      @include respond-to(small) {
        padding: 10px 0;
        border: none;
        border-radius: 0;
        width: 100%;
        font-size: 1rem;
      }
    }

    @include respond-to(small) {
        top: 5%;
        width: 100%;
    }


    a {
      color: black;
    }

    .dropdown-item {
      font-size: 1.2rem;
      background-color: $fontcolor1H;
      border: none;
      padding: 10px;
      cursor: pointer;
      width: 80%;
      text-align: center;
    }


  }
}