section.home-redirect {

  .backoffice-content-container {
    background: none;
    border: none;


    .account-user-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;

      button {
        padding: 10px 20px;
        cursor: pointer;
        background-color: $bgiconscolor;
        border: 2px solid $fontcolor1;
        border-radius: 20px;
        color: $fontcolor1;
        font-size: 1.5rem;
        transition: 0.3s ease;

        &:hover {
          border: 2px solid $fontcolor1H;
          background-color: $bgiconscolorH;
          color: $fontcolor1H;
          transform: scale(1.05);
        }
      }
    }

    section.city-selector {

      .city-display {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include respond-to(small) {
          grid-template-columns: 1fr;
        }

        .button-cities-container {
          .errorPosition {
            color: white;
            background-color: red;
          }

          .problemPosition {
            color: white;
            background-color: orange;
          }

          p {
            margin: 20px auto;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 88%;
            background-color: $bgiconscolor;
            border: 2px solid white;
            border-radius: 20px;
            color: $fontcolor1;
            font-size: 1.5rem;
            transition: 0.3s ease;
            text-align: center;
            @include respond-to(small) {
              width: 70%;
            }

          }
        }

        .other-cities {
          margin-top: 20px;

          .button-cities-container {
            display: block;
            margin: 0 auto;
            width: 50%;
            height: 88%;
            @include respond-to(small) {
              width: 70%;
            }

            button {
              padding: 10px;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              background-color: $bgiconscolor;
              border: 2px solid $fontcolor1;
              border-radius: 20px;
              color: $fontcolor1;
              font-size: 1.5rem;
              transition: 0.3s ease;

              i {
                font-size: 4rem;
                margin-bottom: 10px;
                color: $white;
                @include respond-to(small) {
                  margin-top: 10px;
                  font-size: 3rem;
                }
              }

              &:hover {
                border: 2px solid $fontcolor1H;
                background-color: $bgiconscolorH;
                color: $fontcolor1H;
                transform: scale(1.05);
              }
            }
          }
        }
      }
    }
  }
}