section.statistique {
  .button-container {
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2b259;
    font-size: 1rem;
    padding: 10px;
    border: 2px solid linen;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s ease;
  }
}