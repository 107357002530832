section.privacy-policy {
  width: 90%;
  margin: 10px auto;
  color: $white;
  background: $bgiconscolorH;
  border-radius: 20px;
  padding: 20px;
  border: 2px solid $fontcolor1;

  h1 {
    text-align: center;
    font-size: 3rem;
    @include respond-to(medium) {
      font-size: 2.2rem;
    }
    @include respond-to(small) {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 2rem;
    @include respond-to(medium) {
      font-size: 1.5rem;
    }
    @include respond-to(small) {
      font-size: 1.1rem;
    }
  }

  p {
    font-size: 1.2rem;
    @include respond-to(medium) {
      font-size: 1.1rem;
    }
    @include respond-to(small) {
      font-size: 1rem;
    }
  }
}