section.cart {
  padding: 20px;
  background-color: $bgiconscolor;
  border: 2px solid $fontcolor1;
  border-radius: 20px;
  margin: 20px 20%;
  color: white;

  @include respond-to(small) {
    margin: 20px 5%;
  }

  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $fontcolor1;
    font-size: 1.2rem;

    @include respond-to(small) {
        font-size:1rem;
    }

    .cart-item-title {
      flex: 2;
      margin: 0;
    }

    .cart-item-price {
      align-content: flex-end;
      flex: 1;
      text-align: center;
      margin: 0;
    }

    button {
      background-color: $fontcolor1;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 1.5rem;

      @include respond-to(small) {
        font-size: 1.2rem;
      }
      &:hover {
        background-color: darken($fontcolor1, 10%);
      }
    }
  }

  .cart-summary {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 2px solid $fontcolor1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      font-size: 1.2rem;

      @include respond-to(small) {
        font-size: 1rem;
      }
    }

    .cart-button {
      display: flex;
      gap: 20px;

      button {
        background-color: $fontcolor1;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease;

        @include respond-to(small) {
          font-size: 0.8rem;
          padding: 5px 10px;
          border-radius: 10px;
        }

        &:hover {
          background-color: darken($fontcolor1, 10%);
        }
      }
    }
  }
}