section.guided-tour {
  width: 90%;
  margin: 0 auto;
  background-color: $bgiconscolor;
  border: $borderWhite;
  border-radius: 20px;
  padding: 10px;
  color: $white;
  text-align: center;

  .infos-guided-tour {
    img {
      width: 500px;
      height: 300px;
      border-radius: 10px;
      border: 2px solid $fontcolor1;
      @include respond-to(small) {
        width: 300px;
        height: 200px;
      }
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 40px;
      @include respond-to(small) {
        font-size: 1.5rem;
      }
    }

    a {
      background-color: $fontcolor1;
      border: $borderWhite;
      border-radius: 10px;
      padding: 10px;
      color: $black;
      transition: 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .audio-guided-tour {
    margin-top: 40px;

    h3 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .languages-list {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      margin: 0 auto;
      justify-content: center;
      align-items: center;

      img {
        width: 70px;
        height: 50px;
        border-radius: 10px;
        border: 2px solid $fontcolor1;
        margin: 10px 5px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }
      }

      img.selected {
        width: 100px;
        height: 70px;
        border: 2px solid $fontcolorchecked;
      }
    }

    .audio-selected-language {
      margin-bottom: 20px;

      button {
        background-color: $fontcolor1;
        border: $borderWhite;
        border-radius: 10px;
        padding: 10px;
        color: $black;
        transition: 0.2s ease;
        width: 150px;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}