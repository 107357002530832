.popup-info-partner {
  .popup-info-partner-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 30px;
    width: 30%;
    .popup-info-partner-header {
      display: flex;
      justify-content: right;
      align-items: start;
      margin-bottom: 20px;
      margin-left: 32px;
      img {
        max-width: 80%;
        max-height: 200px;
        border-radius: 20px;
        margin: 0 auto;
      }
      i {
        font-size: 2rem;
        color: $red;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          color: $red2;
        }
      }
    }
    .popup-info-partner-content {
      text-align: center;
      h3 {
        font-size: 2rem;
      }
      .social-media-partner {
        a{
          i{
            cursor: pointer;
            color: $black;
            font-size: 3rem;
            transition: 0.2s ease;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}