section.interview-list {
  width: 70%;
  margin: 0 auto;

  a {
    display: flex;
    margin: 20px 0;
    padding: 30px;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    transition: 0.2s ease;

    @include respond-to(small) {
      padding: 10px;
      margin: 10px 10% 0 10%;
      flex-direction: column;
    }

    img {
      padding: 5px;
      min-width: 200px;
      max-width: 200px;
      height: 75px;
      object-fit: contain;
      background-color: $white;
      border-radius: 10px;
      @include respond-to(small) {
        min-width: 150px;
        max-width: 150px;
        height: auto;
      }
    }

  }
}