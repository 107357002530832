.nav-admin-container {
  margin: 0 auto;
  padding: 0 20px;
  height: 60px;
  width: 100%;
  top: 0;
  background-color: black;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  border-bottom: 2px solid white;
  z-index: 1000;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    li {
      height: 100%;
      width: 100%;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: $white;
        font-size: 1.2rem;
        text-transform: uppercase;
        transition: 0.2s ease;
        &:hover {
          background-color: $fontcolor1H;
          color: $white;
        }
      }
      .nav-active {
        background-color: $fontcolor1;
        color: $black;
      }
    }
  }
}