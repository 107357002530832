form.form-admin-new-magazine, form.form-admin-update-magazine {
  .label-info-magazine-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    max-height: 70px;
    margin-bottom: 40px;

    input {
      width: 23%;
      height: 30px;
    }
  }

  .magazine-image {
    h3 {
      font-size: 1.5rem;
      margin: 23px 0 15px 0;
      text-align: center;
    }

    .magazine-content-container {
      width: 50%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      label, .button-pictures {
        display: flex;
        align-items: center;
        padding: 7px;
        height: 50px;
        background: $white;
        border: $borderBlack;
        border-radius: 10px;
        color: $black;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .uploaded-files-container {
    width: 95%;
    margin: 0 auto;
    border: 2px solid $white;
    border-radius: 10px;

    legend {
      margin-left: 20px;
      color: $black;
      font-size: 1.3rem;
    }

    .uploaded-files {
      display: flex;
      flex-wrap: wrap;

      i {
        color: $red;
        font-size: 2rem;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          color: $red2;
          transform: scale(1.1);
        }
      }

      .picture-new-partner-container {
        display: flex;
        align-items: start;
        margin: 5px 25px 5px 0;

        img {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          margin: 5px 10px;
        }
      }
    }
  }
}