.frontoffice-container{
  header{
    position: relative;
    z-index: 99;
  }
  h1 {
    color: $white;
    font-size: 2rem;
    text-align: center;
    @include respond-to(small) {
      font-size: 1.5rem;
    }
  }

  @include set-background-neutral;

}