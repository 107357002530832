section.popup {
  color: black;

  .popup-update-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 20px;
    width: 70%;
    max-height: 95vh;
    overflow-y: scroll;

    i {
      font-size: 2rem;
      color: $red;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        color: $red2;
      }
    }

    .popup-update-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 20px;

      h2 {
        display: contents;
        color: black;
        font-size: 1.8rem;
      }

      .cancel-button {
        display: flex;
        align-items: center;
        color: $red;
        cursor: pointer;

        p {
          transition: 0.2s ease;
        }

        &:hover {
          p, i {
            color: $red2;
          }
        }
      }
    }

    form {

      label {
        margin: 10px 0 5px;
      }

      input, textarea {
        padding: 10px;
        font-size: 1rem;
        border: $borderBlack;
        border-radius: 10px;
        transition: border-color 0.3s;
        background-color: $white;

        &:focus {
          border-color: #007bff;
          outline: none;
        }
      }

      textarea {
        resize: vertical;
      }


      input[type="submit"]#valid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $white;
        color: $black;
        font-size: large;
        border: $borderBlack;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }
      }

      input[type="submit"]#invalid {
        display: block;
        width: 50%;
        margin: 20px auto;
        background-color: $black;
        color: $white;
        font-size: large;
        border-radius: 10px;
        padding: 5px;
        cursor: not-allowed;
        transition: 0.2s ease;
      }

      .popup-update-content-container {
        margin: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;

        label {
          display: flex;
          align-items: center;
          padding: 7px;
          height: 50px;
          background: $white;
          border: $borderBlack;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .popup-footer {
        display: flex;

        .popup-delete-button {
          margin: 20px 0 20px 0;
          background-color: $red;
          color: $white;
          font-size: large;
          border: 2px solid $red;
          border-radius: 10px;
          padding: 5px;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            background-color: $red2;
            border-color: $red2;
            transform: scale(1.1);
          }
        }
      }
    }

    section {
      margin-bottom: 30px;

      h3 {
        margin-bottom: 15px;
        text-align: center;
        font-size: 1.5rem;
      }

      .form-row {
        margin-bottom: 20px;
      }
    }
  }
}