section.message {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  background-color: $bgiconscolor;
  border: $borderWhite;
  border-radius: 20px;
  padding: 10px;
  color: $white;
  text-align: center;

  .infos-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      margin-bottom: 20px;
      @include respond-to(small) {
        font-size: 1rem;
      }
    }

    img {
      width: 300px;
      height: auto;
      border-radius: 10px;
      border: 2px solid $fontcolor1;
      transition: transform 0.3s ease;

      &.enlarged {
        transform: scale(1.5);
        z-index: 1000;
      }

      @include respond-to(small) {
        width: 200px;
        height: auto;
      }
    }
  }

  .content-message {
    margin-top: 20px;

    p {
      margin: 0 20px 0 20px;
      text-align: justify;
      @include respond-to(small) {
        font-size: 0.8rem;
      }
    }

    h4 {
      margin-top: 10px;
      @include respond-to(small) {
        font-size: 1rem;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;

      li {
        margin: 0 10px;

        button {
          background-color: $fontcolor1;
          color: $black;
          border: $borderWhite;
          border-radius: 10px;
          padding: 5px 10px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            background-color: $fontcolor1;
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .message-uploads {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;

    img {
      margin-bottom: 20px;
      width: 300px;
      height: auto;
      border-radius: 10px;
      border: 2px solid $fontcolor1;
      cursor: pointer;
      transition: transform 0.3s ease;

      &.enlarged {
        transform: scale(1.7);
        z-index: 1000;
      }

      @include respond-to(small) {
        width: 200px;
        height: auto;
      }
    }
  }
}
