section.admin-album {
  .info-album {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 40px;

    .label-input {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      label {
        margin-bottom: 10px;
      }

      #description {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
          cursor: default;
          background: none;
          border: none;
          color: $white;

          &:hover {
            transform: none;
          }
        }
      }
    }
  }

  .info-photo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 80px;

    .uploaded-logo {
      margin-top: 20px;
      text-align: center;
    }

    .uploaded-logo img {
      max-width: 100%;
      height: auto;
      border: 2px solid #ccc;
      border-radius: 4px;
    }

  }

  section.new-music {
    margin-top: 60px;

    h3 {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
    }

    .title-delete {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      h4 {
        margin: 0;
      }

      button {
        position: absolute;
        right: 5%;
        top: -70%;
        transform: translateY(-50%);
        background-color: $red;

        &:hover{
            background-color: $red2;
        }
      }
    }



    .info-music {
      margin: 20px 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    section.uploaded-audio {
      margin-top: 20px;
      text-align: center;
    }

    button {
      @include label-button;
      margin: 40px auto;

    }
  }

  section.new-album {
    textarea {
      width: 100%;
      height: 100%;
      resize: none;
      padding: 10px;
      background: $fontcolor1;
      border: 1px solid $white;
      border-radius: 5px;
      transition: 0.2s ease;

      &::placeholder {
        color: $black2;
      }

      &:hover {
        background: $fontcolor1H;
      }

      &:focus {
        background: $fontcolor1H;
      }
    }

    input {
      width: 100%;
      min-height: 40px;
      margin: 10px 0;
      padding: 10px;
      background: $fontcolor1;
      border: 1px solid $white;
      border-radius: 5px;
      transition: 0.2s ease;

      &::placeholder {
        color: $black;
      }

      &:hover {
        background: $fontcolor1;
      }

      &:focus {
        background: $fontcolor1;
        outline: none;
      }
    }

    .button-label {
      @include label-button;
    }


    input[type="submit"] {
      display: flex;
      width: 50%;
    }


  }

}