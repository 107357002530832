section.guided-tour-list {
  width: 80%;
  margin: 0 auto;
  @include respond-to(small) {
    width: 95%;
  }

  .guided-tour-list-entry {
    a {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      margin: 10px;
      padding: 10px;
      cursor: pointer;
      transition: 0.2s ease;
      position: relative;

      &:hover {

        .distance {
          bottom: 10px;
          right: 20px;
        }
      }

      h2 {
        color: $white;
        margin-left: 10%;
        @include respond-to(medium) {
          font-size: 1rem;
        }
      }

      img {
        width: 200px;
        height: 75px;
        object-fit: cover;
        border-radius: 10px;
        margin: 0 auto;
        @include respond-to(small) {
          width: 140px;
          height: 60px;
        }
      }

      .distance {
        position: absolute;
        align-self: end;
        justify-self: end;
        right: 15px;

        p {
          font-style: italic;
          color: $white;
          font-size: 1rem;

          @include respond-to(medium) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}