.popup-city-social-media {
  .popup-content-city-container {
    background: $fontcolor1;
    border: $borderWhite;
    border-radius: 10px;
    padding: 30px;
    width: 30%;
    .popup-content-city-header {
      display: flex;
      justify-content: right;
      align-items: start;
      margin-bottom: 20px;
      margin-left: 32px;
      img {
        max-width: 80%;
        max-height: 200px;
        border-radius: 10px;
        margin: 0 auto;
        padding: 5px;
        filter: contrast(0%);
      }
      i {
        font-size: 2rem;
        color: $red;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          color: $red2;
        }
      }
    }
    .popup-content-city-social-media {
      text-align: center;
      h3 {
        font-size: 2rem;
      }
      a{
        i{
          cursor: pointer;
          color: $black;
          font-size: 3rem;
          transition: 0.2s ease;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}