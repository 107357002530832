section.widget-pages-jaunes {
  .backoffice-content-container {
    background: none;
    border: none;
    h2{
      background-color: $bgiconscolorH;
    }

    section.pages-jaunes {
      color:black;
      font-size: 10px !important;
      font-family: "Open Sans";
      background-color: $white;
      width: 95%;
      margin: 10px auto;
      border-radius: 20px;
      padding: 1em .7em 8em .7em;

      * {
        box-sizing: border-box;
      }



      h1, h2, h3 {
        background: none;
        font-family: "Open Sans Bold";
        margin: 0;
        padding: 0;
      }

      h1 {
        font-size: 3.2em;
        margin-bottom: 25px;
      }

      .pj_title_sub {
        font-size: 2em;
        margin-bottom: 48px;
      }

      .title-form {
        font-size: 2.6em;
        padding: 20px;
      }

      p {
        font-size: 1.6em;
      }

      > p {
        margin-bottom: 45px;
      }

      .logo-top {
        margin-bottom: 60px;

        img {
          width: $logowidth;
          max-width: 100%;
        }
      }

      .logo-bottom {
        margin-top: 5em;

        img {
          width: $logowidth;
        }
      }

      .pj-search-wrapper {
        margin-bottom: 48px;
      }

      .pj-search {
        box-shadow: 0 0 .4em rgba(0, 0, 0, 0.15);
        border-radius: 0.8em;
        overflow: hidden;
        margin-bottom: 28px;

        form {
          background-color: $yellow;
          padding: 38px 20px 28px 20px;
          position: relative;
        }

        input[type=text],
        button {
          vertical-align: bottom;
        }

        input[type=text] {
          border-radius: $motorheight;
          background-color: $white;
          line-height: $motorheight - 2px;
          max-height: $motorheight;
          width: calc(100% - 2px);
          border: solid 1px $grey2;
          margin-right: -($motorheight + 3px);
          font-size: 11px;
          padding: 10px 20px;

          &.what,
          &.where {
            border-radius: $motorheight;
          }

          &.what {
            margin-right: 0;
            margin-bottom: 10px;
          }

          &.where {
            padding-right: 50px;
          }

          &.pj-inputText {
            padding-right: 50px;
          }
        }

        button {
          background-color: $blue;
          border-radius: 100%;
          border: none;
          overflow: hidden;
          height: calc(#{$motorheight} - 2px);
          width: calc(#{$motorheight} - 2px);
          position: absolute;
          right: 23px;
          bottom: 29px;
          padding: 0;
          transition: .2s;
          box-shadow: -1.86364px 0px 3.72727px rgba(0, 0, 0, 0.12);

          .value {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
          }

          &:before {
            content: '';
            height: calc(#{$motorheight} - 2px);
            width: calc(#{$motorheight} - 2px);
            text-indent: 0px;
            background-image: url('../assets/images/loupe.svg');
            background-repeat: no-repeat;
            background-position: center;
            display: block;
          }
        }
      }
    }

    section.pages-jaunes {
      padding: 1em 3em 8em 4em;

      .title-form {
        font-size: 2.6em;
        padding: 20px 32px;
      }

      .pj-search {
        form {
          padding: 38px 32px 28px 32px;

          &:after {
            content: "";
            clear: both;
            display: table;
          }
        }

        input[type=text] {
          padding: 10px 20px;
          float: left;

          &.what,
          &.where {
            border-radius: $motorheight 0 0 $motorheight;
          }

          &.what {
            width: calc(40% - 2px);
            margin-bottom: 0;
          }

          &.where {
            width: calc(60% - 2px);
            border-radius: 0 $motorheight $motorheight 0;
          }
        }

        button {
          right: 36px;
          top: 39px;
          bottom: inherit;

          &:hover {
            background-color: darken($blue, 15%);
            transition: .2s;
          }
        }
      }
    }
  }
}