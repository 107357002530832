form.form-admin-update-partner {
  .update-partner-setup {
    .info-update-partner {
      text-align: center;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 15px;
      }

      .info-update-partner-container, .link-update-partner, .adress-update, .url-update, .coordinates-update {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        max-height: 70px;
        margin-bottom: 20px;

        .city-select-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 23%;
          margin-bottom: 20px;

        }

        input {
          width: 23%;
          height: 30px;
        }
      }


      .url-update {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        input {
          width: 50%;
          height: 30px;
        }

        #subDomain {
          width: 20%;
        }


        button {
          position: absolute;
          background-color: $white;
          border: $borderBlack;
          border-radius: 10px;
          padding: 5px;
          cursor: pointer;
          transition: 0.2s ease;
          top: 20px;
          right: 3%;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .description-update-partner {
      margin: 10px;

      textarea {
        width: 100%;
        height: 150px;
      }
    }

    .categories-update-partner {
      text-align: center;

      h3 {
        font-size: 1.5rem;
        margin: 23px 0 15px 0;
      }
    }

    .social-media-update-partner {
      text-align: center;

      h3 {
        font-size: 1.5rem;
        margin: 23px 0 15px 0;
      }

      .social-media-update-partner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;

        input {
          width: 95%;
          height: 30px;
        }

        .social-media-update {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .update-partner-content {
    h3 {
      font-size: 1.5rem;
      margin: 23px 0 15px 0;
      text-align: center;
    }

    .popup-update-content-container {
      width: 95%;
    }
  }

  .uploaded-logo {
    margin-left: 35px;

    img {
      border-radius: 10px;
      border: 2px solid $fontcolor1;
      margin: 5px 0;
    }
  }

  .uploaded-files-container {
    width: 95%;
    margin: 0 auto;
    border: 2px solid $white;
    border-radius: 10px;

    legend {
      margin-left: 20px;
      color: $black;
      font-size: 1.3rem;
    }

    .uploaded-files {
      display: flex;
      flex-wrap: wrap;

      i {
        color: $red;
        font-size: 2rem;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          color: $red2;
          transform: scale(1.1);
        }
      }

      .picture-new-partner-container {
        display: flex;
        align-items: start;
        margin: 5px 25px 5px 0;

        img {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          margin: 5px 10px;
        }
      }

      .video-new-partner-container {
        display: flex;
        align-items: start;
        margin: 5px 25px 5px 0;

        video {
          border-radius: 10px;
          border: 2px solid $fontcolor1;
          cursor: pointer;
        }
      }
    }
  }

  .logo-interview {
    display: flex;

    .uploaded-logo {
      margin-left: 35px;

      img {
        border-radius: 10px;
        border: 2px solid $fontcolor1;
        margin: 5px 0;
      }
    }
  }
}

.interview-update-partner {
  text-align: center;
  margin: 20px 0;

  h3 {
    font-size: 1.5rem;
    margin: 23px 0 15px 0;
  }

  input {
    height: 30px;
  }

  .interview-informaton-update {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .interview-update {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .interview-text-update {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 30%;
    }

    textarea {
      width: 100%;
      height: 70px;
    }

    button {
      width: 80%;
      padding: 10px;
      background-color: white;
      color: black;
      border: $borderBlack;
      border-radius: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $fontcolor1;
      }
    }

    .modal-content {
      width: 80%;
      max-width: 800px;

      .textarea-container {
        position: relative;

        .modal-close {
          position: absolute;
          width: 30px;
          top: 2px;
          right: 10px;
          font-size: 1rem;
          background-color: $fontcolor1;
          border: none;;
          cursor: pointer;
          color: $red;

          &:hover {
            color: $red2;
            background-color: white;
          }
        }
      }
    }

  }
}