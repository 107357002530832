.logo-container {
    padding-top: 5vh;
    display: block;

  &.pwa-active {
    padding-top: 15vh;

    @include respond-to(small){
      padding-top: 5vh;
    }
  }

  .logo {
    max-width: 500px;
    max-height: 100%;
    padding-top: 20px;
    margin: 0 auto 20px;
    @include respond-to(small) {
      padding-top: 70px;
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .logoLBP{
    padding-top: 0;
    width: 100%;
    max-width: 500px;
    margin: 50px auto;
    display: block;
    border-radius: 20px;
    border: 2px solid $white;
    background-color: $bgiconscolor;
    @include respond-to(small) {
      margin-top: 80px;
      width: 80%;
    }
  }

}