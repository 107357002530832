.city-list-entry-container {
  margin-bottom: 40px;
  margin-top: 20px;
  a {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 500px;
    height: 100%;
    margin: 0 auto;
    background-color: $black;
    border: $borderWhite;
    border-radius: 20px;
    transition: 0.3s ease;
    position: relative;
    @include respond-to(small) {
      width: 80%;
      height: fit-content;
    }
    img {
      border-radius: 20px 20px 0 0;
      width: 100%;
      height: 90%;
      max-height: 250px;
      object-fit: cover;
      margin: 0 auto;
      display: block;
    }
    .nameDistance {
      color: $black;
      width: 100%;
      flex-grow: 1;
      margin: 0 auto;
      font-size: 1.5rem;
      background-color: $fontcolor1;
      border: hidden;
      align-items: center;
      border-radius: 0 0 17px 17px;
      transition: 0.3s ease;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: flex-end;
    }
    .name {
      margin-right: 20%;
    }
    @include respond-to(small) {
      .name {
        margin-right:0;
      }
    }
    .distance {
      position: absolute;
      bottom: 1px;
      right: 15px;
      font-size: 1rem;
      color: $black;
      @include respond-to(small) {
        bottom: 5px;
        right: 10px;
      }
      @include respond-to(medium) {
        position: static;
      }
    }
    &:hover {
      transform: scale(1.05);
      button {
        background-color: $fontcolor1H;
      }
    }
  }
}