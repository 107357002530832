section.iframe-link {

  .button-container {
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2b259;
    font-size: 1rem;
    padding: 10px;
    border: 2px solid linen;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s ease;
  }

  .iframe-container {
    width: 80%;
    margin: 10px auto 0;
    @include respond-to(small) {
      width: 95%;
    }

    iframe {
      width: 100%;
      height: 85vh;
      border-radius: 20px;
      scrollbar-color: $fontcolor1;
    }
  }
} 