table.admin-table {
  width: 95%;
  margin: 20px auto;
  color: $fontcolor1;

  &, th, td {
    border-collapse: collapse;
    border: 2px solid $white;
    padding: 5px;

    .first-category {
      color: red;
    }

    &.contain-btn {
      text-align: center;

      button:disabled {
        &:hover {
          color: red;
        }
      }
    }

    img {
      max-height: 70px;
      max-width: 150px;
    }

    .city-logo {
      filter: contrast(0%);
    }
  }

  button {
    width: 100%;
    background-color: $fontcolor1;
    border: 1px solid $white;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: $fontcolor1H;
      color: $white;
    }
  }

  tr.deleted {
    background-color: $red;
  }

  @include respond-to(small) {
    width: 100%;
    margin: 10px 0;

    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 25px;
      border: 2px solid $white;
    }

    td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      border-bottom: 2px solid $fontcolor1;
      padding: 8px 10px;

      &:last-child{
        border:none;
      }

      &:before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 10px;
      }
    }

    button {
      width:40%;
      padding: 8px;
      font-size: 14px;
    }
  }
}