form.form-admin-update-user {
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .form-group {
      flex: 1 1 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

    }
  }
}
