@import 'popupBackofficeSetUp';
section.admin-album {
  form.form-admin-update-album {

    .popup-update-content-container {
      .label-input {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        align-items: center;
        justify-content: center;

        label {
          width: 90%;
        }
      }
    }

    section.new-music {

      button {
        background: $white;
        border: $borderBlack;
        transition: 0.2s ease;

        &:hover {
          background-color: $white;
        }
      }

      .info-music {
        gap: 70px;
        margin-left: 120px;

        .label-input{
          margin-bottom: 20px;
        }

        .button-label {
          display: flex;
          align-items: center;
          padding: 7px;
          height: 50px;
          background: $white;
          border: $borderBlack;
          border-radius: 10px;
          color: $black;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover {
            transform: scale(1.1);
          }
        }

        input {
          width: 100%;
        }
      }
    }
  }
}
