.news-list-container {
  width: 25%;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow-y: auto;

  &.link {
    max-height: 50vh;
  }

  &.manual {
    max-height: 80vh;
  }
}

.news-item {
  background: $fontcolor1;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.news-item img {
  border-radius: 4px;
  margin-bottom: 10px;
}

.news-item h4 {
  margin: 0;
  font-size: 16px;
  color: black;
}

.news-item p {
  margin-top: 10px;
  color: white;
}